.notifications {
  padding-top: 36px;
  .custom-tabs {
    &__items {
      padding: 0;
      background: none;
      margin-bottom: 14px;
    }
  }

  &__title {
    margin-bottom: 36px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  @media screen and (max-width: $mobile) {
    .custom-tabs {
      &__block {
        width: calc(100% + 40px);
        transform: translateX(-20px);
      }
    }
  }
}