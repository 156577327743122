.database-detail {
  padding-top: 36px;

  .badge-info {
    margin-top: 36px;
    margin-bottom: 0;
  }

  &__case {
    padding: 36px;
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 24px;
    padding-bottom: 3px;
    font-family: $FInter, $FDefault;
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid $primary2;
  }


  &__subtitle,
  h2 {
    margin-bottom: 14px;
    padding-bottom: 3px;
    font-family: $FInter, $FDefault;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid $primary2;
  }

  &__description,
  p {
    margin-bottom: 14px;
    font-family: $FInter, $FDefault;
    font-size: 16px;

    a {
      font-family: $FInter, $FDefault;
      color: $primary4;
      font-size: 16px;
    }
  }

  &__image {
    margin-bottom: 36px;

    &-text {
      margin-top: 14px;
      font-family: $FInter, $FDefault;
      font-size: 16px;
    }
  }

  &__list {
    &>li>ul {
      list-style: disc;

      li {
        margin-left: 20px;
        list-style: disc;
      }
    }
  }

  ol {
    list-style: disc;
    margin-bottom: 14px;

    li {
      margin-left: 20px;
      list-style: disc;
    }
  }

  table {
    margin-bottom: 14px;

    td {
      border: 1px solid;

      p {
        margin-bottom: 0;
        padding: 5px;
      }
    }
  }


  &__item {
    font-family: $FInter, $FDefault;
    font-size: 16px;
  }

  &__content {
    margin-bottom: 24px;
    padding: 14px 20px;
    width: fit-content;
    border-radius: 16px;
    border: 1px solid $primary2;

    &-title {
      font-family: $FInter, $FDefault;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 8px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      counter-reset: num;
    }

    &-item {
      font-family: $FInter, $FDefault;
      font-size: 16px;

      a {
        font-family: $FInter, $FDefault;
        color: $primary4;
        font-size: 16px;
      }

      &::before {
        content: counter(num);
        counter-increment: num;
        display: inline-block;
        margin-right: 4px;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    padding-top: 0;
  }

  @media screen and (max-width: $mediumMobile) {
    &__container {
      .container {
        padding: 0;
      }
    }

    &__comment {
      .container {
        padding: 0;
      }
    }
  }
}