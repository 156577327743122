.users {
  padding-top: 36px;

  &__filters {
    display: flex;
    margin-bottom: 36px;
    column-gap: 24px;

    .search {
      flex: 1 1 auto;
    }

    .dropdown-menu {
      overflow: initial;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    column-gap: 20px;
    row-gap: 36px;
    margin-top: 24px;
    margin-bottom: 65px;
  }

  .dropdown-menu {
    width: 220px;
    .search {
      margin: 0px 14px;
    }
  }

  .dropdown-search {
    .dropdown-menu {
      width: 253px;
      background: #FFFFFF;
      padding: 20px 0px;
    }

    .dropdown-item {

      background: none;

      &:not(:last-child) {
        border: none;
      }

      a {
        color: #778599;
      }

      &:hover {
        a {
          color: #404041;
        }
      }

    }
  }

  .dropdown-item {
    label {
      width: 100%;
      padding: 9.5px 14px;
    }
  }

  &__filter {
    height: 100%;
  }

  &__dropdown {
    &-caption {
      padding-left: 14px;
      font-weight: 600;
      margin-bottom: 14px;
    }

    &-list {
      margin-bottom: 20px;
    }

    &-btn {
      margin: 0px 14px;
      margin-top: 20px;

      .btn {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    &__list {
      gap: 20px;
      margin-bottom: 36px;
    }

    &__filters {
      gap: 8px;
    }

    &__filter {
      .btn {
        &__text {
          display: none;
        }
      }
    }

    .dropdown-menu {
      border-top: 3px solid #17aa5a;
      // padding: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      top: 10px !important;
    }

    &__filters {
      &:has(.dropdown-menu.show){
        &::before {
          content: "";
          position: absolute;
          left: -20px;
          top: 57px;
          z-index: 1;
          width: calc(100% + 40px);
          height: 100vh;
          background-color: rgba(119, 133, 153, .25);
        }
      }
      
    }
  }
}