.book-detail {
    padding-top: 36px;

    &__case {
        margin: 20px 0px;
        display: flex;
    }

    &__info {
        display: flex;
        padding: 20px 24px 37px 20px;
        gap: 20px;
    }

    &__title {
        margin-bottom: 24px;
    }

    &__text {
        font-family: $FInter, $FDefault;
        font-size: 18px;
        line-height: 26px;
    }

    &__tabs {
        // padding: 24px 36px 46px 36px;

        margin-bottom: 36px;
        &-container {
            padding: 0px 36px 46px 36px;
            border-radius: 0 0 24px 24px;
            background-color: #ffffff;
        }
    }

    &__left {
        width: 220px;
        height: 295px;
        flex: 0 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__characteristic {
        background-color: #E2F4ED;
        border-radius: 24px;
        padding: 36px;
        min-width: 287px;

        &-btn {
            .btn {
                &__container {
                    gap: 4px;
                }
            }
        }

        &-title {
            margin-bottom: 20px;
            font-family: $FInter, $FDefault;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

        &-text {
            margin-bottom: 24px;
            font-family: $FInter, $FDefault;
            font-size: 14px;
            line-height: 20px;
        }

        &-price {
            display: flex;
            align-items: center;
            margin-bottom: 36px;
            font-family: $FInter, $FDefault;
            color: $primary4;
            font-size: 32px;
            font-weight: 700;
            gap: 8px;
        }
    }

    &__authors {
        &-text {
            margin-bottom: 36px;
            font-family: $FInter, $FDefault;
            font-size: 16px;
            line-height: 22px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            &-title {
                font-family: $FInter, $FInter;
                font-size: 14px;
                line-height: 17px;
                color: $primary2;
            }

            &-img {
                width: 100%;
                border-radius: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }
        }
    }

    &__description {
        display: flex;
        column-gap: 46px;
        
        &-left {
            display: flex;
            flex-direction: column;
            row-gap: 36px;            
        }
        &-text {
            font-family: $FInter, $FDefault;
            font-size: 16px;
            line-height: 22px;
        }

        &-right {
            flex: 0 0 auto;
            img {
                max-width: 386px;
                width: 100%;
                max-height: 300px;
                border-radius: 20px;
            }
        }
    }

    &__reviews {
        &-item {
            padding: 36px;
            &:first-child {
                padding-top: 0px;
                border-radius: 0 0 24px 24px;
            }

            &-text {
                font-size: 16px;
                line-height: 22px;
            }

            &-title {
                margin-top: 36px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                text-align: right;
            }
        }

        &-items {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
        }
    }

    @media screen and (max-width: $laptop) {
        // &__left {
        //     width: 180px;
        //     height: auto;
        // }
        &__case {
            flex-direction: column;
        }

        &__description {
            &-right {
                img {
                    max-width: 280px;
                }
            }
        }
    }

    @media screen and (max-width: $table) {
        &__info {
            flex-direction: column;
            padding: 0;
        }

        &__case {
            padding: 36px 20px;
            row-gap: 24px;
        }

        &__left {
            text-align: center;
            width: 100%;
            img {
                max-width: 220px;
            }
        }

        &__tabs {
            &-container {
                padding: 0px 20px 36px 20px;
            }
        }

        &__reviews {
            &-item {
                padding: 0px 20px 36px 20px;
            }
        }

        &__description {
            flex-direction: column;
            row-gap: 24px;
            &-right {
                text-align: center;
            }
        }
    }

    @media screen and (max-width: $smallMobile) {
        &__characteristic {
            padding: 26px;
        }
    }
}