.researchbase-detail {
  padding-top: 36px;

  &__title {
    display: flex;
    justify-content: space-between;
    column-gap: 4px;
    row-gap: 14px;
    margin-bottom: 8px;
  }

  .badge-info {
    margin-bottom: 0;
  }

  &__case {
    padding: 39px 36px 55px 36px;
    margin-bottom: 20px;
  }

  &__name {
    margin-bottom: 20px;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    color: $primary2;
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 20px;

    &-item {
      padding-bottom: 8px;
      &:not(:last-child) {
        border-bottom: 1px solid $primary2;
      }
      &-container {
        display: flex;
        column-gap: 36px;
      }

      &-title {
        font-family: $FInter, $FDefault;
        font-weight: 600;
        font-size: 16px;
        color: $primary1;
      }

      &-text {
        font-family: $FInter, $FDefault;
        font-weight: 400;
        font-size: 16px;
        color: $primary1;
      }
    }
  }

  &__subtitle {
    margin: 20px 0;
  }

  &__description {
    margin-bottom: 12px;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    color: $primary1;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 56px;
  }

  &__item {
    padding-top: 20px;
    border-top: 1px solid $primary2;

    &-title {
      margin-bottom: 12px;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      li {
        font-family: $FInter, $FDefault;
        font-size: 16px;
        color: $primary7;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    &__title {
      flex-direction: column;
      .badge-status {
        order: -1;
      }
    }
  }

  @media screen and (max-width: $mediumMobile) {
    &__container {
      .container {
        padding: 0;
      }
    }

    &__info {
        &-item {
            &-container {
                justify-content: space-between;
            }
        }
    }

    &__case {
      padding: 20px 20px 36px 20px;
    }

    &__img {
      width: calc(100% + 40px);
      transform: translateX(-20px);
      img {
        width: 100%;
      }
    }

    &__comment {
      .container {
        padding: 0;
      }
    }
  }
}
