.book {
    padding-top: 36px;

    .banner {
        background-color: #355FC9;

        &__title {
            color: #ffffff;
        }

        &__text {
            color: #ffffff;
        }

        img {
            margin: auto;
        }

        &__right {
            padding-right: 36px;
        }
    }

    &__list {
        display: grid;
        gap: 24px;
        margin-top: 36px;
        grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
    }

    &__item {
        display: flex;
        padding: 32px 20px;

        &-text {
            color: #404041;
        }

        &-left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 194px;
            height: 194px;
            overflow: hidden;
            border-radius: 14px;
            flex: 0 0 auto;

            img {
                object-fit: none;
                object-position: top;
            }
        }

        &-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-btn {
            padding: 8px 20px;
            font-size: 12px;
        }

        &-title {
            margin-bottom: 12px;
        }

        &-text {
            font-family: $FInter, $FDefault;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
        }

        &-info {
            margin-bottom: 24px;
            font-family: $FInter, $FDefault;
            font-size: 12px;
            font-weight: 500;
            color: $primary2;
            padding: 6px 11px;
            background-color: #E2F4ED;
            border-radius: 12px;

            span {
                color: $primary4;
                font-weight: 600;
            }
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
        }

        &-price {
            display: flex;
            column-gap: 9px;
            font-weight: 800;
            font-size: 18px;
            font-family: $FInter, $FDefault;
            color: #404041;
        }
    }

    @media screen and (max-width: $laptop) {
        .banner {
            width: 100%;

            &::after {
                display: none;
            }

            &__right {
                position: relative;
                width: 200px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 14px;
        .banner {
            &__right {
                position: absolute;
                width: 100%;
                opacity: 0.4;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 36px;
        }

        &__item {
            flex-direction: column;
            align-items: center;
            gap: 12px;

            &-left {
                width: 180px;
                height: 180px;

                img {
                    object-fit: contain;
                }
            }

            &-footer {
                flex-direction: column;
                align-items: start;
                gap: 24px;
            }

            &-btn {
                width: 100%;
                padding: 14px 20px;
                font-size: 16px;
            }
        }
    }
}