.webinar {
  padding-top: 40px;
  .slide {
    .video__watch a {
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__comment {
    margin-bottom: 36px;
    &-container {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    .comment {
      margin-bottom: 0px;
    }
  }

  &__card {
    &-wrapper {
      min-height: 319px;
      padding: 36px;
    }

    &-case {
      max-width: 706px;
    }

    &-title {
      margin-bottom: 8px;
    }

    &-texts {
      border-top: 1px solid #778599;
      border-bottom: 1px solid #a5b2c3;
      padding: 12px 0px 8px 0px;
    }

    &-text {
      margin-bottom: 20px;
      color: #778599;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }

    &-caption {
      margin-bottom: 12px;
      color: #4a4a4a;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }

    &-name {
      margin-bottom: 12px;
      color: #4a4a4a;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }

    &-description {
      color: #4a4a4a;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }

    &-list {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    &-link {
      color: $primary7;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  &__material {
    margin-bottom: 56px;
    .case {
      padding: 36px 20px;
    }

    &-container {
      display: flex;
      column-gap: 24px;
    }

    &-document {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        margin-top: 25px;
      }

      iframe, .iframe {
        width: 389px;
        height: 285px;
      }
    }

    &-video {
      //   width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow: hidden;

      iframe {
        width: 100%;
        height: 100%;
        // width="593" height="332"
      }
      .badge-info {
        margin-top: 20px;
        margin-bottom: 0;
      }

      &--closed {
        position: relative;
      }

      &-closed {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(#000000, 0.85);
        color: #ffffff;

        &-title {
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 12px;
        }

        &-text {
          font-size: 16px;
          max-width: 320px;
          text-align: center;
          margin-bottom: 15px;
        }

        &-price {
          display: flex;
          align-items: center;
          gap: 4px;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 48px;
        }

        .btn {
          max-width: 292px;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: $laptop) {
    &__material {
      &-container {
        flex-direction: column;
        row-gap: 20px;
      }

      &-video {
        iframe {
          height: 300px;
        }
      }

      &-document {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          margin-top: 25px;
        }

        iframe {
          width: 389px;
          height: 285px;
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    padding-top: 14px;
    &__card {
      &-wrapper {
        min-height: auto;
        padding: 24px 20px 36px 20px;
      }
    }

    &__comment {
      margin-bottom: 24px;
      .comment {
        //margin-bottom: 36px;
      }
    }

    &__material{
      margin-bottom: 36px;
      &-document {
        .bx-pdf-container {
          width: 100% !important;
        }

        &-btn {
          width: 100%;
        }

        .btn {
          padding: 8px 25px;
        }
      }
    }
  }

  @media screen and (max-width: $mediumMobile) {
    &__material {
      .container {
        padding: 0;
      }

      &-video {
        iframe {
          height: 250px;
        }
      }

      &-document {
        iframe {
          width: 100%;
          max-width: 389px;
          height: 285px;
        }
        .btn {
          width: 100%;
          font-size: 12px;
        }
      }
    }

    &__card {
      .container {
        padding: 0;
      }
    }

    &__comment {
      .container {
        padding: 0;
      }

      .comment {
        &__profile {
          display: none;
        }

        &__wrapper {
          padding: 24px 14px;
        }

        &__btn {
          .btn {
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}
