.webinars {
  display: flex;
  flex-direction: column;
  padding-top: 36px;

  &__banners {
    display: flex;
    column-gap: 24px;
  }

  &__caption {
    margin-top: 36px;
  }

  &__filter {
    // margin-top: 36px;
    margin-bottom: 12px;

    .dropdown-menu {
      width: 242px;
      border-top: 3px solid #17AA5A;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    .search {
      width: 100%;
    }

    .btn {
      white-space: nowrap;

      &.show {
        color: #06774e;
        border: 1px solid #06774e;
        svg {
          fill: #06774e;
        }
      }

      svg {
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
    }

    &-container {
      display: flex;
      column-gap: 24px;
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 24px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 5px 5px 8px 0px rgba(106, 110, 131, 0.08);
    margin-bottom: 14px;
  }

  &__category {
    label, .label {
      display: flex;
    }

    input {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }

    span, .button {
      cursor: pointer;
      padding: 12px 39px;
      border-radius: 28px;
      font-weight: 600;
      color: black;
    }

    input:checked+span, .button.selected {
      background-color: #17AA5A;
      color: #FFFFFF;
    }
  }

  &__list {
    display: grid;
    column-gap: 24px;
    row-gap: 36px;
    grid-template-columns: repeat(3, 1fr);
  }

  .slide {
    margin: 36px 0px;
    // width: calc(100% + 20px);
    // transform: translateX(-20px);

    .video {
      &__watch {
        a {
          text-decoration: none;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .pagination {
    margin-top: 56px;
  }

  .banner {
    width: 100%;

    &__text {
      max-width: 540px;
    }

    &__container {
      justify-content: space-between;
    }

    &__right {
      align-items: center;
      justify-content: center;
      flex: 1 1 auto;
      max-width: 310px;
      //flex: 1 1 auto;

      img {
        margin: inherit;
      }
    }
  }

  @media screen and (max-width: $laptop) {
    &__list {
      column-gap: 14px;
      row-gap: 20px;
    }

    &__banners {
      .banner {
        &__right {
          position: inherit;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: $table) {
    &__banners {
      flex-direction: column;
      row-gap: 24px;

      .banner {
        width: 100%;

        &__right {
          display: none;
          // top: 0;
          // bottom: inherit;
        }
      }
    }

    &__categories {
      gap: 8px;
    }

    &__category {
      span {
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #fff;
        line-height: 15px;
      }

      input:checked+span {
        background-color: inherit;
        color: #17AA5A;
        border: 1px solid #17AA5A;
      }
    }

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: $mobile) {
    &__banners {
      flex-direction: column;
      row-gap: 24px;

      .banner {
        width: 100%;

        &__right {
          top: inherit;
          bottom: 0;
          height: 86%;
        }
      }
    }

    &__filter {
      &-container {
        gap: 8px;
        .btn {
          &__text {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: $mediumMobile) {
    &__list {
      row-gap: 20px;
      grid-template-columns: repeat(1, 1fr);

      .video {
        max-width: none;
      }
    }
  }

  @media screen and (max-width: $smallMobile) {
    &__banners {
      flex-direction: column;
      row-gap: 24px;

      .banner {
        width: 100%;

        &__right {
          top: inherit;
          bottom: 0;
          height: auto;
        }
      }
    }
  }
}
