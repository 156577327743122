.database {
    padding-top: 36px;

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin: 20px 0;
    }

    &__header {
        display: flex;
        gap: 8px;

        &:has(.dropdown-menu.show) {
            &::before {
                content: '';
                position: absolute;
                left: -20px;
                top: 57px;
                z-index: 1;
                width: calc(100% + 40px);
                height: 100vh;
                background-color: rgba(119, 133, 153, 0.25);
            }
        }

        .btn {
            display: none;
            &.show {
                border: 1px solid #17AA5A;

                svg {
                    fill: #17AA5A;
                }
            }
        }

        .search {
            flex: 1 1 auto;
        }

        .dropdown-menu {
            padding: 0px;
            overflow: hidden;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            &__container {
                border-top: 3px solid #17AA5A;
                background-color: #ffffff;
                padding: 36px 20px;
                min-height: 200px;
                max-height: 60dvh;
                overflow: auto;
            }
        }
    }

    &__dropdown {
        display: none;
        position: inherit;

        &-title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        
    }

    @media screen and (max-width: $mobile) {
        padding-top: 14px;

        &__header {
            .btn {
                display: block;
                padding: 8px 14px;
            }
        }

        &__dropdown {
            display: block;
        }
    }
}