.main {
  display: flex;
  flex-direction: column;
  padding-top: 36px;

  &__watch {
    height: 100vw;
    max-height: 778px;
  }

  &__video {

    &--active {
      .main__video-up {
        display: none;
      }
    }

    &:not(.main__video--active) {
      .main__watch {
        display: none;
      }
    }

    &-up {
      position: relative;
      padding: 50px;
      height: 100vw;
      max-height: 778px;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(50%);
      }
    }

    &-down {
      position: relative;
      margin-top: -20px;
      z-index: 2;
      padding: 36px;
    }

    &-caption {
      margin-bottom: 12px;
    }

    &-list {
      list-style: disc;

      li {
        margin-left: 20px;
        list-style: disc;
      }

      margin-bottom: 24px;
    }

    &-btn {
      max-width: 261px;
      width: 100%;
    }

    &-play {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-title {
      position: relative;
      z-index: 4;
      font-size: 64px;
      font-weight: 700;
      color: #ffffff;
    }
  }

  .slide {
    margin: 36px 0px;
    // width: calc(100% + 20px);
    // transform: translateX(-20px);

    .video {
      &__watch {
        a {
          text-decoration: none;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  &__programm {
    margin-top: 55px;
    margin-bottom: 56px;

    &-container {
      padding: 36px;
    }

    &-title {
      margin-bottom: 20px;
    }

    &-text {
      max-width: 749px;
      margin-bottom: 36px;
      color: #4a4a4a;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 47px;
      column-gap: 20px;
      margin-bottom: 52px;

      &--mobile {
        display: none;
      }
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: #E2F4ED;
      border-radius: 16px;
      padding: 27px 16px 32px 20px;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 46px;
        height: 46px;
        border-radius: 100%;
        background-color: #17AA5A;
        top: -23px;
        left: 0;
        // margin-right: 27px;
      }

      &-title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 8px;
      }

      &-text {
        color: #404041;
        font-family: $FInter, $FDefault;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__new {
    margin-bottom: 80px;

    &-list {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(3, minmax(0px, 1fr));
      margin-bottom: 36px;

      .news:first-child {
        grid-area: 1/1/3/1;
      }
    }

    &-btn {
      text-align: center;
    }
  }

  &__study {
    &-list {
      display: grid;
      column-gap: 20px;
      row-gap: 24px;
      grid-template-columns: repeat(3, 1fr);

      //   margin-bottom: 36px;
      .study:last-child {
        grid-area: 1/3/3/3;
      }
    }
  }

  &__community {
    display: flex;
    gap: 12px;
    margin-bottom: 57px;

    .banner {
      position: relative;
      padding-bottom: 178px;

      .btn {
        max-width: 185px;
        width: 100%;
        padding: 13.5px 28px;
      }

      &__title {
        font-size: 24px;
      }

      &__container {
        flex-direction: column;
      }

      &__right {
        position: absolute;
        right: 10px;
        bottom: 36px;
        width: 275px;

        img {
          width: 100%;
        }
      }
    }

    &-banner {
      &-two {
        &.banner {
          background-color: #D8E7FF;

          .btn {
            color: #404041;
          }
        }
      }
    }

  }

  &__subscription {
    margin-bottom: 56px;

    &-container {
      display: flex;
      align-items: flex-start;
      gap: 56px;
      padding: 36px 71px 57px 23px;
    }

    &-left {
      .btn {
        max-width: 166px;
        width: 100%;
      }
    }

    &-title {
      margin-bottom: 20px;
    }

    &-text {
      margin-bottom: 24px;
    }

    &-subtitle {
      font-size: 20px;
      margin-bottom: 12px;
    }

    &-list {
      margin-bottom: 24px;
    }

    &-price {
      display: flex;
      align-items: center;
      gap: 14px;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 24px;
      color: #404041;
    }

    &-caption {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 12px;
    }

    &-right {
      background-color: #E2F4ED;
      border-radius: 16px;
      padding: 36px 20px 30px 20px;
      flex: 0 0 324px;

      .main__subscription-list {
        margin-bottom: 14px;
      }

      .main__subscription-price {
        margin-bottom: 32px;
      }

      .btn {
        width: 100%;
      }
    }
  }

  &__banners {
    margin-bottom: 28px;

    .banner {
      &__container {
        justify-content: space-between;
      }

      &-one {
        .btn {
          color: #404041;
        }

        background-color: #D8E7FF;


      }

      &__left {
        max-width: 583px;
      }

      &__right {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: initial;
          width: 100%;
          height: max-content;
        }
      }
    }

    .swiper {
      padding-bottom: 24px;

      &-pagination {
        &-bullets {
          position: absolute;
          bottom: 0 !important;
          display: flex;
          justify-content: center;
          gap: 4px;
        }

        &-bullet {
          margin: 0 !important;
          background-color: #A5B2C3;
          opacity: 1;

          &-active {
            background-color: #17AA5A;
          }
        }
      }
    }
  }

  &__experience {
    &-title {
      margin-bottom: 36px;
    }

    &-case {
      display: flex;
    }

    &-left {
      padding: 56px 0px 20px 36px;
      max-width: 362px;
    }

    &-cards {
      display: flex;
      gap: 32px;
      padding: 36px 20px;
    }

    &-caption {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &-text {
      color: #4A4A4A;
      margin-bottom: 36px;
    }

    &-btn {
      width: 100%;

      &--mobile {
        display: none;
      }
    }
    
    &-swiper {
      display: none;
    }

    .database-card {
      flex: 1 1 50%;
      border: 1px solid #778599;
      padding: 12px 12px 24px 20px;

      .badge-status {
        justify-content: flex-end;
      }

      &__description {
        -webkit-line-clamp: 7;
      }

      &__footer {
        flex-direction: column;
        align-items: flex-start;
      }
    }

  }

  .banner {
    &--big {
      background-color: #2BB77B;

      .btn {
        color: #404041;
      }

      .banner {
        &__title {
          color: #ffffff;
        }

        &__text {
          color: #ffffff;
        }

        &__right {
          align-items: center;
          padding: 45px;

          img {
            margin-top: initial;
            max-width: 239px;
          }
        }


      }
    }
  }

  .swiper-programms {
    display: none;
  }

  @media screen and (max-width: $laptop) {

    .banner {
      &--big {
        &::after {
          display: none;
        }

        width: 100%;

        .banner {

          &__left {
            display: initial;
          }

          &__right {
            position: initial;
            align-items: center;
            padding: 45px;
            width: initial;

            img {
              margin-top: initial;
              max-width: 239px;
            }
          }
        }
      }
    }

    &__new {
      &-list {
        // display: flex;
        // flex-direction: column;
        // flex-wrap: wrap;
        // align-items: center;
        // height: 600px;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__study {
      &-list {
        grid-template-columns: repeat(2, 1fr);

        .study {
          &:last-child {
            grid-area: 1/3/3/2;
          }
        }
      }
    }

    &__programm {
      &-container {
        padding: 24px;
        column-gap: 6px;
      }
    }
  }

  @media screen and (max-width: $table) {
    .banner {
      &--big {
        .banner {
          &__right {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 0;

            img {
              opacity: 0.4;
            }
          }
        }
      }

      &--custom-img {
        .banner {

          &__right {
            position: absolute;
            right: 0;
            bottom: 0px;
          }
        }
      }
    }

    &__experience {

      .database-card {
        flex: none;
      }

      &-cards {
        display: none;
      }

      &-case {
        flex-direction: column;
      }

      &-left {
        padding: 36px 20px 20px 20px;
      }

      &-swiper {
        display: block;
      }

      &-btn {
        display: none;
  
        &--mobile {
          display: flex;
          margin: 0 20px;
          margin-bottom: 36px;
        }
      }

      .swiper {
        padding-bottom: 24px;
        margin-bottom: 20px;

        &-pagination {
          &-bullets {
            display: flex;
            gap: 4px;
            justify-content: center;
            position: absolute;
            bottom: 0 !important;
          }

          &-bullet {
            margin: 0 !important;
            opacity: 1;
            background-color: #A5B2C3;

            &-active {
              background-color: #17AA5A;
            }
          }
        }
      }
    }


    &__banners {
      .swiper {
        padding-bottom: 50px;
      }
    }

    &__programm {
      &-container {
        padding: 24px;
        column-gap: 6px;
      }

      &-text {
        max-width: none;
      }

      // &-right {
      //   display: none;
      // }

      &-left {
        width: 100%;
      }

      &-item {
        height: 100%;
      }

      &-list {
        display: none;

        //   &--mobile {
        //     margin-bottom: 24px;
        //     display: flex;
        //   }
      }

      .btn {
        width: 100%;
        justify-content: center;
      }

      .swiper {
        padding-top: 25px;
        padding-bottom: 24px;
        margin-bottom: 36px;

        &-slide {
          display: flex;
          flex-direction: column;
          gap: 35px;
        }

        &-pagination {
          &-bullets {
            position: absolute;
            bottom: 0 !important;
            display: flex;
            justify-content: center;
            gap: 4px;
          }

          &-bullet {
            background-color: #A5B2C3;
            opacity: 1;
            margin: 0 !important;

            &-active {
              background-color: #17AA5A;
            }
          }
        }
      }
    }

    &__subscription {
      &-container {
        padding: 0;
        flex-direction: column;
        gap: 0
      }

      &-left {
        padding: 36px 20px 24px 20px;

        .btn {
          width: 100%;
        }
      }

      &-right {
        flex: auto;
        padding: 36px 20px 30px 20px;
        width: 100%;

        .btn {
          width: 100%;
        }
      }
    }

    .swiper-programms {
      display: block;
    }



    &__community {
      flex-direction: column;
    }
  }

  @media screen and (max-width: $mobile) {
    padding-top: 0;
    
    &--two {
      padding-top: 14px;
    }

    .banner {
      &--big {
        .btn {
          width: 100%;
        }
      }
    }

    &__video {
      padding: 0;

      &-title {
        display: none;
      }

      &-play {
        width: 30px;
        height: 30px;
      }

      &-down {
        padding: 36px 20px;

        .btn {
          max-width: initial;
        }
      }
    }

    &__programm {
      .container {
        padding: 0;
      }

      &-container {
        padding: 36px 20px;
      }

      &-right {
        width: calc(100% + 40px);
        transform: translateX(-20px);

        .swiper {
          padding: 25px 20px;
        }
      }
    }

    &__community {
      gap: 87px;
      margin-bottom: 97px;

      .banner {
        padding-bottom: 157px;

        img {
          max-width: 267px;
        }

        .btn {
          width: 100%;
        }

        &__right {
          left: 50%;
          transform: translateX(-50%);
          bottom: -50px;
        }
      }

      &-banner {
        &-two {
          order: -1;

          .banner {
            &__right {
              right: -20px;
              left: initial;
              transform: initial;
              bottom: -70px;
            }
          }
        }
      }
    }

    &__subscription {
      margin-bottom: 36px;
    }
  }
}