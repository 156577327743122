.basket {
    // background-color: red;

    &__title {
        margin-top: 20px;
        margin-bottom: 36px;
    }

    &__wrapper {
        display: flex;
        gap: 20px;
    }

    &__result {
        width: 355px;
        min-height: 243px;
        background-color: #E2F4ED;
        border-radius: 20px;
        padding: 36px 24px;
    }

    &__caption {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1 1 auto;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__result {
        position: sticky;
        top: 20px;

        &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
        }

        &-col {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &-line {
            width: 100%;
            height: 1px;
            margin: 14px 0px;
            background-color: #778599;
        }

        &-total {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 14px;
        }

        .btn {
            width: 100%;
            margin-top: 21px;
        }

        .custom-checkbox {
            label {
                font-size: 14px;
            }

            span {
                width: 20px;
                height: 20px;
            }
        }

        &-auth {
            &-text {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }

        &-info {
            margin-top: 16px;
            font-size: 12px;

            span {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    &__form {
        padding: 36px 20px 56px 20px;

        &-container {
            display: flex;
            flex-direction: column;
            gap: 12px;

            &:not(:last-child) {
                margin-bottom: 36px;
            }
        }

        &-question {
            &-title {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 8px;
            }

            .custom-checkbox {
                label {
                    font-size: 14px;
                }

                span {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &-caption {
            font-size: 18px;
            font-weight: 600;
        }

        &-info {
            font-size: 14px;
            margin-top: -4px;
            color: $primary10;
        }

        &-tab {
            display: flex;
            gap: 36px;
            margin-bottom: 24px;
        }

        &-result {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: fit-content;
            padding: 12px 20px;
            border-radius: 16px;
            margin-top: 12px;
            background-color: $primary3;
            border: 1px solid #A5B2C3;

            &-title {
                font-size: 14px;
                font-weight: 600;
            }

            &-text {
                font-size: 16px;
                font-weight: 700;

                &--gray {
                    color: $primary2;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }

        .custom-radio {

            &__text {
                font-size: 14px;
                font-weight: 400;
            }

            span {
                width: 20px;
                height: 20px;

                &::after {
                    width: 14px;
                    height: 14px;
                }
            }

            input:checked {
                ~span {
                    &::after {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }

        .custom-field {
            &__title {
                font-weight: 600;
            }
        }
    }

    @media screen and (max-width: $table) {
        &__wrapper {
            flex-direction: column;
        }

        &__result {
            position: inherit;
            width: 100%;
        }

        &__right {
            position: sticky;
            bottom: -12px;
            z-index: 4;
        }
    }
}