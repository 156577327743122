//colors
$primary1: #404041;
$primary2: #778599;
$primary3: #e8ecf5;
$primary4: #17aa5a;
$primary5: #2bb77b;
$primary6: #355c90;
$primary7: #3c94d0;
$primary8: #36c3df;
$primary9: #f8f9fd;
$primary10: #e63323;

$bgColor: linear-gradient(90deg, #35c2de 2.86%, #3a72b6 100%);

//font

$FInter: 'Inter';
$FDefault: 'Arial';

//media

$laptop: 1439px;
$table: 1023px;
$mobile: 767px;
$mediumMobile: 576px;
$smallMobile: 374px;