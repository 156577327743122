.news-and-events-item {
  padding-top: 36px;
  &__comment {
    &-container {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    .comment {
      margin-bottom: 56px;
    }
  }

  &__post {
    margin-bottom: 36px;
    &-case {
      padding: 36px 36px 64px 36px;
    }

    &-up {
      display: flex;
      column-gap: 20px;
      margin-bottom: 24px;

      &-left {
        flex: 1 1 500px;
      }

      &-right {
        max-width: 486px;
        flex: 1 1 486px;
      }
    }

    &-title {
      margin-bottom: 24px;
    }

    &-text {
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }

    &-link {
      color: $primary7;
    }

    &-description {
      margin-bottom: 36px;
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;

      img {
        display: block;
        margin: 0 auto;
      }

      p {
        margin-bottom: 24px;
      }

      ul {
        list-style: disc;
        margin-bottom: 24px;
      }

      ul > li {
        margin-left: 20px;
        list-style: disc;
      }

      a {
        word-wrap: break-word;
      }
    }

    &-info {
      display: flex;
      justify-content: space-between;

      &-texts {
        color: $primary2;
        font-family: $FInter, $FDefault;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        text-align: right;
      }
    }
  }
  @media screen and (max-width: $table) {
    &__post {
      &-case {
        padding: 24px 20px 36px 20px;
      }

      &-up {
        row-gap: 24px;
        &-left {
          flex: auto;
        }

        &-right {
          display: flex;
          justify-content: center;
          flex: auto;
          max-width: none;
          img {
            max-width: 486px;
            width: 100%;
          }
        }

        flex-direction: column;
      }

      &-info {
        flex-direction: column;
        align-items: flex-end;
        row-gap: 36px;
        &-texts {
          order: -1;
        }
      }
    }
  }

  @media screen and (max-width: $mobile){
    &__post {
      &-description {
        margin-bottom: 24px;
      }
    }
  }
}
