.news-and-events {
  padding-top: 36px;

  .slides-big {
    margin-bottom: 60px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    margin-top: 36px;
    margin-bottom: 24px;
  }

  &__item {
    &:nth-child(2n + 1) {
      .news:first-child {
        grid-area: 1/1/3/1;
      }
    }
    &:nth-child(2n + 2) {
      .news:last-child {
        grid-area: 1/3/3/3;
      }
    }
    &-case {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: $laptop) {
    &__item {
      &:nth-child(2n + 2) {
        .news:last-child {
          order: -1;
          grid-area: 1 / 2 / 3 / 3;
        }
      }
      &-case {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media screen and (max-width: $table) {

    .slides-big {
      margin-bottom: 36px;
    }

    &__list {
      row-gap: 20px;
    }

    &__item {
      &:nth-child(2n + 2) {
        .news:last-child {
          grid-area: 1/1/3/1;
        }
      }
      &-case {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
      }
    }
  }
}
