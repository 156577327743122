.libary {
    &__banners {
        .banner {
            background-color: #355FC9;

            &__title {
                color: #ffffff;
            }

            &__text {
                color: #ffffff;
            }

            &::after {
                display: none;
            }
        }
    }

    @media screen and (max-width: $laptop) {
        &__banners {
            .banner {
                &__right {
                    position: relative;
                    width: 200px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        &__banners {
            .banner {
                &__right {
                    position: absolute;
                    bottom: 24px;
                    width: 100%;
                    opacity: 0.4;
                }
            }
        }
    }
}