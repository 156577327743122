.researchbase-create {
  padding-top: 36px;
  .custom-file-upload {
    width: 100%;
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      justify-content: center;

      svg {
        display: none;
      }
    }
  }
  .custom-field {
    &__container {
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      column-gap: 17px;
      margin-left: auto;
      &-field {
        flex: 1 1 auto;
        width: 100%;
        display: flex;
        gap: 8px;
        max-width: 772px;
        flex-wrap: wrap;
      }
    }

    &__title {
      flex: 0 0 120px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .custom-select {
    .dropdown {
      width: 100%;
      max-width: 772px;
    }

    &__select-item {
      border-radius: 10px;
      border: 1px solid #a5b2c3;
      padding: 4px;
    }

    &__container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      column-gap: 17px;

      &-select {
        display: flex;
        gap: 10px;
       flex-wrap: wrap;
        width: 100%;
        max-width: 772px;
      }
    }

    &__title {
      flex: 0 0 120px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__case {
    padding: 36px;
  }

  &__title {
    margin-bottom: 36px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 992px;
    row-gap: 24px;
  }

  &__btns {
    display: flex;
    column-gap: 20px;
    margin-left: auto;
    max-width: 772px;
    width: 100%;
  }

  @media screen and (max-width: $mobile) {
    .custom-file-upload {
      span {
        display: flex;
        flex-direction: column;
        gap: 4px;
        svg {
          display: block;
        }
      }
    }

    .dropdown-menu {
      width: 100%;
    }
  }

  @media screen and (max-width: $mediumMobile) {
    padding-top: 14px;

    &__form {
      row-gap: 12px;
    }

    &__case {
      padding: 24px 20px;
    }

    &__container {
      .container {
        padding: 0;
      }
    }

    .custom-field {
      &__container {
        align-items: flex-start;
        flex-direction: column;
      }

      &__title {
        flex: auto;
      }
    }

    .custom-select {
      &__container {
        align-items: flex-start;
        flex-direction: column;
      }

      &__title {
        flex: auto;
      }
    }

    &__btns {
      flex-direction: column;
      gap: 14px;
    }
  }
}
