.cro {
    padding-top: 36px;

    .banner {
        background-color: $primary5;

        &__title {
            color: #ffffff;
        }

        &__text {
            color: #ffffff;
        }

        img {
            margin: auto;
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
        }

        &__container {
            padding: 12px 46px 12px 36px;
        }
    }

    .custom-field {
        &--radio {
            .custom-field {
                &__container-field {
                    gap: 12px;
                }
            }
        }

        input {
            font-size: 14px;
        }
    }

    .comment__field {
        font-size: 14px;
        padding: 8px 15px;
        height: 72px;

        &::placeholder {
            font-size: 14px;
        }
    }

    .custom-radio {
        &__text {
            font-weight: 400;
        }
    }

    &__form {
        margin-top: 36px;
        padding: 36px 36px 56px 36px;

        &-field {
            &-checkbox {
                margin-bottom: 14px;
                .custom-checkbox {
                    span {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }

        .custom-checkbox {
            label {
                font-size: 16px;
            }
        }

        &-title {
            margin-bottom: 24px;
        }

        &-step {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #A5B2C3;
        }

        &-text {
            max-width: 704px;
        }

        &-description {
            margin-bottom: 8px;
        }

        &-info {
            margin-top: 36px;
            margin-bottom: 24px;
            border-radius: 12px;
            background-color: #E2F4ED;
            padding: 24px 38px 36px 24px;

            &-title {
                display: flex;
                align-items: center;
                gap: 8px;
                color: $primary2;
                margin-bottom: 11px;
            }

            &-text {
                color: $primary2;
                line-height: 22px;
            }
        }

        &-subtitle {
            padding-bottom: 14px;
            border-bottom: 1px solid #A5B2C3;
            margin-top: 36px;
            margin-bottom: 24px;
        }

        &-fields {
            &-col {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            &-row {
                display: flex;
                width: 100%;
                column-gap: 23px;

                &--small {

                    .custom-field,
                    .dropdown {
                        &:nth-child(1) {
                            max-width: 703px;
                        }
                    }

                    .custom-field,
                    .dropdown {
                        &:nth-child(2) {
                            width: auto;
                            flex: 1 1 auto;
                        }
                    }
                }

                .custom-field,
                .dropdown {
                    width: 100%;
                }
            }
        }

        &-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 36px;
            gap: 12px;
            
            &-case {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                
            }
        }
    }


    @media screen and (max-width: $laptop) {
        .banner {
            width: 100%;

            &::after {
                display: none;
            }

            &__right {
                position: relative;
                width: 200px;
            }
        }
    }

    @media screen and (max-width: $table) {
        &__form {
            &-fields {
                &-row {
                    row-gap: 12px;
                    flex-direction: column;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 14px;

        .banner {
            &__right {
                position: absolute;
                width: 100%;
                opacity: 0.4;
            }

            &__left {
                padding: 24px;
            }

            &__container {
                padding: 0;
            }
        }

        .custom-field--radio {
            .custom-field__container-field {
                flex-direction: column;
            }
        }

        &__form {
            width: calc(100% + 40px);
            transform: translateX(-20px);
            padding: 36px 20px;

            &-title {
                margin-bottom: 16px;
            }

            &-btn {
                flex-wrap: wrap;

                gap: 24px;

                &-case {
                    display: flex;
                    gap: 12px;
                    width: 100%;
                }

                &-submit {
                    order: -1;
                }

                .btn {
                    width: 100%;
                }
            }

            &-btn-add {
                width: 100%;

                .btn {
                    width: 100%;
                }
            }
        }

        &__questions {
            padding: 24px 20px;

            &-text {
                margin-bottom: 24px;
            }

            &-btn {
                width: 100%;

                .btn {
                    width: 100%;
                }
            }
        }
    }
}