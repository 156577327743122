.expert-review {
    padding-top: 36px;

    .step {
        &__item {
            flex: 1;
            padding: 26px 20px 36px 20px;

            &-text {
                margin-bottom: 0;
            }
        }
    }

    .banner {
        background-color: $primary5;

        &__title {
            color: #ffffff;
        }

        &__text {
            color: #ffffff;
            margin-bottom: 0;
        }

        &__right {
            max-width: 239px;
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
        }

        &__container {
            padding: 12px 46px 12px 36px;
        }

        img {
            margin: auto;
        }
    }

    &__recommendation {
        margin-top: 56px;
        padding: 36px;

        &-title {
            margin-bottom: 14px;
        }

        &-text {
            max-width: 726px;
            margin-bottom: 24px;

            a {
                color: $primary7;
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }


    &__form {
        margin-top: 56px;
        padding: 36px 36px 56px 36px;

        &-btns {
            display: flex;
            gap: 20px;
        }

        &-checkboxes {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            margin-bottom: 36px;
            max-width: 944px;

            .custom-checkbox {
                label {
                    align-items: flex-start;
                    font-size: 16px;
                }
                div {
                    a {
                        color: $primary7;
                        text-decoration: underline;
                        text-underline-offset: 4px;
                    }
                }
            }
        }

        &-title {
            margin-bottom: 36px;
        }

        &-subtitle {
            margin-bottom: 20px;
        }

        &-info {
            max-width: 772px;
            margin: 20px 0px 20px auto;
            text-align: center;
        }

        &-attention {
            display: flex;
            align-items: start;
            gap: 4px;
            max-width: 772px;
            margin: 36px 0px 36px auto;
            color: $primary10;
            font-size: 18px;

            svg {
                flex: 0 0 auto;
            }
        }

        .custom-field {
            &__container {
                justify-content: flex-end;
                align-items: stretch;
                flex-direction: row;
                column-gap: 17px;
                margin-left: auto;

                &-field {
                    flex: 1 1 auto;
                    width: 100%;
                    display: flex;
                    gap: 8px;
                    max-width: 772px;
                    flex-wrap: wrap;

                    &:has(.custom-checkbox) {
                        max-width: none;
                    }

                }
            }

            &__title {
                display: flex;
                align-items: center;
                justify-content: right;
                flex: 0 0 147px;
                text-align: right;
                font-size: 14px;
            }
        }

        .custom-select {
            &__container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: row;
                column-gap: 17px;
                margin-left: auto;

                &-select {
                    width: 100%;
                    max-width: 772px;
                }
            }

            &__title {
                flex: 0 0 147px;
                text-align: right;
                font-size: 14px;
            }
        }

        &-container {
            max-width: 944px;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &-fields {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }

    &__upload {
        max-width: 772px;
        margin-left: auto;

        span {
            display: flex;
            flex-direction: column;
        }

        .custom-file-upload {

            &__title {
                color: $primary1;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 12px;

                &-decor {
                    display: inline-block;
                    color: $primary7;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                }

                &--mobile {
                    display: none;
                    font-weight: 600;
                    font-size: 14px;
                    color: $primary7;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                }
            }

            &__text {}
        }
    }


    @media screen and (max-width: $laptop) {
        .banner {
            width: 100%;

            &::after {
                display: none;
            }

            &__right {
                position: relative;
                width: 200px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 14px;

        &__recommendation {
            width: calc(100% + 40px);
            transform: translateX(-20px);
            padding: 24px 20px 36px 20px;

            &-text {
                a {
                    white-space: nowrap;
                }
            }
        }

        .banner {
            &__right {
                position: absolute;
                width: 100%;
                opacity: 0.4;
                max-width: none;
            }

            &__left {
               padding: 24px;
            }

            &__container {
                padding: 0;
            }
        }

        &__form {
            .custom-file-upload {
                svg {
                    width: 24px;
                    height: 24px;
                }

                span {
                    gap: 8px;
                }

                &__text {
                    display: block;
                    color: $primary2;
                    max-width: 154px;
                    font-size: 12px;
                    text-align: center;
                }

                &__title {
                    display: none;
                    margin-bottom: 0;

                    &--mobile {
                        display: block;
                    }
                }
            }

            &-info {
                margin: 12px 0px 12px auto;
            }

            &-attention {
                margin-top: 24px;
                margin-bottom: 32px;
            }
        }
    }

    @media screen and (max-width: $mediumMobile) {

        &__form {
            width: calc(100% + 40px);
            transform: translateX(-20px);
            padding: 24px 20px 36px 20px;

            .custom-field {
                &__container {
                    align-items: flex-start;
                    flex-direction: column;
                }

                &__title {
                    flex: auto;
                }
            }

            .custom-select {
                &__container {
                    align-items: flex-start;
                    flex-direction: column;
                }

                &__title {
                    flex: auto;
                }
            }

            &-btns {
                display: flex;
                flex-direction: column;
                gap: 14px;
            }
        }
    }
}