.account {
  padding-top: 36px;

  &__title {
    margin-bottom: 24px;
  }

  &__left {
    grid-area: 1/1/5/1;

    &-container {
      padding: 36px;
    }

    &-btn {
      text-align: center;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      margin-bottom: 56px;

      &:last-child {
        margin-bottom: 36px;
      }
    }

    .dropdown {
      &-menu {
        width: 100%;
      }
    }
  }

  &__profile {
    padding: 34px 25px 22px 25px;

    &-container {
      display: flex;
      column-gap: 32px;
    }

    &-img {
      position: relative;
      margin-bottom: 10px;
      border: 1px solid $primary2;
      border-radius: 100%;
      overflow: hidden;
      max-width: 168px;
      height: 168px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &-add {
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
        transition: all 0.3s ease-in-out;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 1;
            transition: all 0.3s ease-in-out;
          }
        }

        span {
          color: #fff;
          font-family: $FInter, $FDefault;
          font-size: 12px;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    &-title {
      margin-top: 16px;
      margin-bottom: 24px;
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
    }

    &-location {
      display: flex;
      align-items: center;
      color: $primary2;
      font-family: $FInter, $FDefault;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
    }

    &-badges {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 52% auto;
    column-gap: 36px;
    row-gap: 24px;
    margin-bottom: 36px;
  }

  &__subscribe {
    padding: 36px 36px 56px 36px;

    &-btn {
      &--disabled {
        cursor: default;
        pointer-events: none;
        background: #E8ECF5;
        color: #A5B2C3;
      }
    }

    .btn {
      &:disabled {
        background: #E8ECF5;
        color: #A5B2C3;
      }
    }

    &-title {
      color: $primary1;
      margin-bottom: 14px;
    }

    &-text {
      margin-bottom: 36px;
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &-status {
      margin-bottom: 8px;
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;

      &--error {
        color: $primary10;
      }

      &--success {
        color: $primary4;
      }
    }

    &-date {
      color: $primary2;
      margin-bottom: 56px;
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    &-pay {
      margin-bottom: 41px;
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 32px;
      font-weight: 700;
      line-height: normal;

      &--gray {
        color: #A5B2C3;
        svg {
          fill: #A5B2C3;
        }
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  &__subscribe-banner {
    padding: 24px;
    box-shadow: 5px 5px 8px 0px rgba(106, 110, 131, 0.08);
    background: linear-gradient(95deg, #ffb802 1.77%, #ff7a00 99.58%);

    &--product {
      background: #FBEDC3;

      .account__subscribe-banner {
        &-title {
          color: #404041;
        }

        &-text {
          color: #404041;
          a {
            color: #3C94D0;
          }
        }
      }
    }

    &-title {
      color: #fff;
      margin-bottom: 14px;
    }

    &-text {
      color: #fff;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &__settings {
    padding: 36px 36px 26px 36px;

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      column-gap: 4px;

      svg {
        flex: 0 0 auto;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      margin-bottom: 36px;
    }

    &-btn {
      text-align: center;
    }
  }

  &__documents {
    margin-bottom: 36px;

    &-case {
      padding: 36px 36px 60px 36px;
    }

    &-title {
      display: flex;
      align-items: center;
      column-gap: 4px;
      margin-bottom: 24px;
    }

    &-list {
      display: flex;
      column-gap: 24px;
      border-bottom: 1px solid #778599;
      padding-bottom: 12px;
    }

    &-tab {
      position: relative;
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      &::before {
        content: '';
        position: absolute;
        opacity: 0;
        bottom: -14px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $primary4;
        transition: all 0.2s ease-in-out;
      }

      transition: all 0.2s ease-in-out;

      &.active {
        font-weight: 600;

        &::before {
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }

        transition: all 0.2s ease-in-out;
      }
    }

    &-block {
      display: none;
      padding-top: 24px;

      &.show {
        display: block;
      }
    }

    &-accordion {
      &-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0px;
        border-bottom: 1px solid #a5b2c3;
        width: 100%;

        &:not(.collapsed) {
          .account__documents-accordion-btn-arrow {
            transform: rotate(180deg);
            transition: all 0.3s ease-in-out;
          }
        }

        &-arrow {
          flex: 0 0 auto;
          transition: all 0.3s ease-in-out;
        }

        &-info {
          display: flex;
          align-items: center;
          column-gap: 8px;
          text-align: start;

          color: $primary1;
          font-family: $FInter, $FDefault;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;

          svg {
            flex: 0 0 auto;
          }
        }
      }

      &-upload {
        padding: 20px 0px;
      }
    }
  }

  &__donation {
    padding: 36px 36px 50px 36px;

    &-description {
      margin-top: 14px;
      margin-bottom: 24px;
      color: #000;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;

      a {
        color: $primary7;
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }

    &-text {
      margin-bottom: 24px;
      color: #000;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  @media screen and (max-width: $laptop) {
    &__wrapper {
      grid-template-columns: 41% auto;
      column-gap: 16px;
      row-gap: 16px;
    }
  }

  @media screen and (max-width: $table) {
    &__wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 36px;
    }

    &__left {
      order: 4;
    }

    &__settings {
      order: 5;
    }
  }

  @media screen and (max-width: $mobile) {
    &__left {
      width: calc(100% + 40px);
      transform: translateX(-20px);
    }

    &__documents {
      .container {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: $mediumMobile) {
    &__profile {
      padding: 24px;

      &-title {
        font-size: 18px;
      }

      &-container {
        column-gap: 16px;
      }

      .btn {
        font-size: 12px;
      }

      &-img {
        width: 100px;
        height: 100px;
      }
    }

    &__subscribe {
      padding: 29px 20px 36px;

      .btn {
        width: 100%;
      }
    }

    &__settings {
      padding: 24px 20px 36px 20px;

      .btn {
        width: 100%;
      }
    }

    &__left {
      &-container {
        padding: 36px 20px;

        .btn {
          width: 100%;
        }
      }
    }

    &__documents {
      &-case {
        padding: 36px 20px;
      }
    }

    &__donation {
      padding: 36px 24px;

      &-description {
        font-size: 16px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }
}