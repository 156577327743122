.researchbase {
  padding-top: 36px;
  .banner {
    margin-bottom: 56px;
    background-color: $primary5;
    &__title {
      color: #ffffff;
    }

    &__text {
      color: #ffffff;
    }

    &__container {
      align-items: center;
    }

    .btn {
      color: $primary1;
    }
  }

  .database-card {
    position: relative;
    padding-top: 45px;
    .badge-status {
      position: absolute;
      right: 36px;
      top: 36px;
    }
  }

  &__list {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  @media screen and (max-width: $laptop) {
    .banner {
      width: 100%;

      &::after {
        display: none;
      }

      &__right {
        position: relative;
        width: 200px;
      }

      &__left {
        display: block;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    padding-top: 14px;
    .banner {
      margin-bottom: 36px;
      width: 100%;

      &__right {
        width: 100%;
        height: 100%;
        position: absolute;
        img {
          opacity: 0.4;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &__left {
        display: flex;
      }
    }

    .database-card {
      position: relative;
      padding-top: 12px;
      .badge-status {
        justify-content: flex-end;
        position: relative;
        // right: 36px;
        // top: 36px;
        right: 0;
        top: 0;
      }
    }
  }
}
