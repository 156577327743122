.services {
    padding-top: 36px;

    .banner {

        &--big {
            margin-bottom: 24px;
            background-color: #355FC9;

            &::after {
                display: none;
            }

            .banner {
                &__title {
                    color: #ffffff;
                }

                &__text {
                    color: #ffffff;
                }

                img {
                    margin: auto;
                }
            }
        }

        &--custom-img {
            background-color: #D8E7FF;

            .btn {
                color: $primary1;
            }
        }

    }

    &__case {
        &-info {
            padding: 36px;
        }

        &-paid-webinars {
            padding: 36px;
        }
    }

    &__banner {
        &-main {
            .banner {
                &__right {
                    img {
                        object-fit: contain;
                        margin: auto;
                    }
                }
            }
        }
    }

    &__info {
        &-title {
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 12px;
        }

        &-text {
            margin-bottom: 24px;
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-price {
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 700;
            font-size: 24px;
        }
    }

    &__paid-webinars {

        .table {
            display: none;
        }

        .desctop {
            display: block;
        }

        &-title {
            margin-bottom: 12px;
        }

        &-text {
            margin-bottom: 24px;
            max-width: 750px;
        }

        &-btn {
            margin-bottom: 36px;
        }

        &-items {
            display: flex;
            gap: 20px;

            .video {
                flex: 1 1 33%;
            }
        }

        .video__watch a {
            text-decoration: none;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
        }
    }

    @media screen and (max-width: $laptop) {
        .banner {
            &--big {
                width: 100%;

                .banner {
                    &__right {
                        position: relative;
                        width: 200px;

                    }
                }
            }


        }
    }

    @media screen and (max-width: 1199px) {
        .table {
            display: block;
        }

        .desctop {
            display: none;
        }

        .slide {
            width: calc(100% + 72px);
            transform: translateX(-36px);

            // padding: 0 32px;
            .swiper {
                width: calc(100% + 72px);
                transform: translateX(0px);
                padding: 0 32px;
            }

            &__pagination {
                margin-top: 24px;
                display: flex;
                justify-content: center;
            }

        }
    }

    @media screen and (max-width: $table) {
        .banner {
            &--custom-img {
                margin-bottom: 86px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 14px;
        .banner {
            &--big {
                .banner {
                    &__right {
                        position: absolute;
                        width: 100%;
                        opacity: 0.4;
                    }
                }
            }
        }

        .slide {
            width: calc(100% + 40px);
            transform: translateX(-20px);

            .swiper {
                padding: 0 20px;
            }
        }

        &__case {
            &-paid-webinars {
                transform: translateX(-20px);
                width: calc(100% + 40px);
                padding: 36px 20px;
            }

            &-info {
                padding: 36px 24px;
            }
        }

        &__info {
            &-bottom {
                align-items: flex-start;
                gap: 24px;
                flex-direction: column;

                .btn {
                    width: 100%;
                }
            }
        }
    }
}