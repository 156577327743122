.standart {
  padding-top: 36px;

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;
    margin-bottom: 65px;
  }

  .back-header {
    margin-bottom: 36px;
  }

  @media screen and (max-width: $mobile) {
    padding-top: 14px;
  }
}
