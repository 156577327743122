.user-item {
  padding-top: 36px;
  &__card {
    &-wrapper {
      display: flex;
      column-gap: 36px;
      //padding: 24px;
      padding: 24px 24px 56px 24px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &-right {
      flex: 1 1 auto;
    }

    &-img {
      position: relative;
      &-case {
        max-width: 246px;
        max-height: 246px;
        overflow: hidden;
        border-radius: 24px;
      }

      &-verified {
        position: absolute;
        width: 64px;
        height: 64px;
        bottom: -20px;
        left: -20px;
      }
    }

    &-badges {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &-list {
      display: flex;
      margin-top: 36px;
    }

    &-item {
      flex: 1 1 50%;

      &:not(:first-child) {
        padding-left: 36px;
        border-left: 1px solid #a5b2c3;
      }

      &-title {
        margin-bottom: 12px;
        color: #4a4a4a;
        font-family: $FInter, $FDefault;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }

      &-info {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        &-title {
          margin-bottom: 12px;
          color: #a5b2c3;
          font-family: $FInter, $FDefault;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
        }

        &-text {
          color: $primary1;
          font-family: $FInter, $FDefault;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  @media screen and (max-width: $table) {
    &__card {
      &-wrapper {
        flex-direction: column;
        row-gap: 20px;
      }

      &-left {
        flex-direction: row;
        column-gap: 16px;
      }

      &-img {
        &-verified {
          width: 36px;
          height: 36px;
          left: 0;
          bottom: -14px;
        }
      }

      &-list {
        flex-direction: column;
        margin-top: 20px;
        row-gap: 24px;
      }

      &-item {
        &:not(:first-child) {
          padding-left: 0;
          border-left: none;
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    padding-top: 14px;
  }
}
