.subscription {
    padding-top: 36px;

    .banner {
        &--custom-img {
            margin-bottom: 62px;
            background-color: #0F5CD9;
            overflow: visible;

            .banner {
                &__title {
                    color: #ffffff;
                    max-width: 493px;
                }

                &__text {
                    color: #ffffff;
                    max-width: 493px;
                }

                &__info {
                    display: flex;
                    align-items: center;
                    max-width: 212px;
                    width: 100%;
                    padding: 14px;
                    gap: 12px;
                    background-image: url('/assets/images/decor-banner.png');
                    background-repeat: no-repeat;
                    background-size: contain;

                    color: #0F5CD9;
                    font-weight: 700;
                    font-size: 24px;
                }

                &__right {
                    align-items: center;
                    justify-content: center;
                    flex: 1 1 auto;
                    padding-top: 31px;

                    img {
                        margin-bottom: -40px;
                    }
                }
            }
        }
    }

    .table {
        display: none;
    }

    .desctop {
        display: block;
    }

    &__case {
        &-info {
            padding: 36px;
        }

        &-stages {
            padding: 36px;
        }
    }

    &__info {
        &-title {
            margin-bottom: 24px;
            max-width: 440px;
        }

        &-text {
            max-width: 750px;
            margin-bottom: 24px;
        }

        &-items {
            display: flex;
            gap: 21px;
        }

        &-btn {
            margin-bottom: 24px;
        }

        &-item {
            flex: 1 1 33.333%;
            background-color: #E2F4ED;
            border-radius: 24px;
            padding: 36px 24px;
            min-height: 260px;
            box-shadow: 5px 5px 8px 0px rgba(106, 110, 131, 0.08);

            &-title {
                font-size: 24px;
                font-weight: 600;
                color: $primary4;
                margin-bottom: 12px;
                word-break: break-word;
            }

            &-text {
                color: $primary2;
            }
        }
    }

    &__stages {
        &-title {
            display: flex;
            gap: 4px;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 20px;
        }

        &-text {
            color: $primary2;
            margin: 20px 0px;
        }

        &-btn {
            margin-top: 20px;
        }

        &-list {
            
            &-one {
                li {
                    color: $primary2;
                    padding-left: 4px;
                }
            }
            
            &-two {
                list-style: disc;
                
                li {
                    color: $primary2;
                    list-style: disc;
                    margin-left: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 1199px) {
        .table {
            display: block;
        }

        .desctop {
            display: none;
        }

        .slide {
            width: calc(100% + 72px);
            transform: translateX(-36px);

            // padding: 0 32px;
            .swiper {
                width: 100%;
                transform: translateX(0px);
                padding: 0 32px;
                padding-bottom: 15px;
            }

            &__pagination {
                margin-top: 24px;
                display: flex;
                justify-content: center;
            }

        }
    }

    @media screen and (max-width: $table) {
        .banner {
            &--custom-img {
                margin-bottom: 130px;

                .banner {
                    &__right {
                        right: 30px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: 14px;
        &__case {
            &-info {
                transform: translateX(-20px);
                width: calc(100% + 40px);
                padding: 36px 20px;
            }

            &-stages {
                transform: translateX(-20px);
                width: calc(100% + 40px);
                padding: 36px 20px;
            }
        }

        &__stages {
            &-btn {
                width: 100%;
            }
        }

        .slide {
            width: calc(100% + 40px);
            transform: translateX(-20px);

            .swiper {
                padding: 0 20px;
            }
        }
    }

    @media screen and (max-width: $mediumMobile) {
        .banner {
            &--custom-img {
                .banner {

                    &__right {
                        left: 50%;
                        right: inherit;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}