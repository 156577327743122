.associations {
    padding-top: 36px;

    .mobile {
        display: none;
    }

    .desctop {
        display: block;
    }

    &__case {
        padding: 56px 36px;

        &-green {
            background-color: #E2F4ED;
            padding: 24px 24px 48px 24px;
        }

        &-structure {
            padding: 36px;
        }

        &-governance {
            padding: 36px;
        }

        &-documents {
            padding: 36px;
        }
    }

    &__caption {
        margin-bottom: 8px;
        padding-bottom: 3px;
        border-bottom: 1px solid #778599;
    }

    &__description {
        margin-bottom: 20px;
    }

    &__link {
        color: $primary7;
        border-bottom: 2px solid $primary7;
    }

    &__green {

        &-header {
            display: flex;
            align-items: flex-start;
            gap: 20px;

        }

        &-body {
            padding-left: 65px;
        }
    }

    &__structure {
        &-caption {
            max-width: 480px;
            margin-bottom: 12px;
        }
        //.h3 {
        //    max-width: 480px;
        //    margin-bottom: 12px;
        //}
    }


    &__governance {
        &-title {
            margin-bottom: 24px;
        }

        &-cards {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            gap: 24px;
            margin-bottom: 24px;
        }
    }

    &__documents {
        &-text {
            margin-top: 12px;
            font-size: 18px;
        }

        &-accordion {
            &-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 0px;
                border-bottom: 1px solid #a5b2c3;
                width: 100%;

                &:not(.collapsed) {
                    .associations__documents-accordion-btn-arrow {
                        transform: rotate(180deg);
                        transition: all 0.3s ease-in-out;
                    }
                }

                &-arrow {
                    flex: 0 0 auto;
                    transition: all 0.3s ease-in-out;
                }

                &-info {
                    display: flex;
                    align-items: center;
                    column-gap: 4px;
                    text-align: start;
                    color: #404041;
                    font-family: $FInter, $FDefault;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 20px;

                    span {
                        color: $primary2;
                        font-weight: 400;
                    }

                    svg {
                        flex: 0 0 auto;
                    }
                }
            }

            &-items {
                display: flex;
                flex-direction: column;
                gap: 4px;
                padding: 12px;
            }

            &-item {
                cursor: pointer;
                display: flex;
                gap: 10px;
                justify-content: space-between;
                color: #404041;

                svg {
                    flex: 0 0 auto;
                    transition: all 0.3s ease-in-out;
                }
                transition: all 0.3s ease-in-out;
            
                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        color: $primary4;
                        transition: all 0.3s ease-in-out;

                        svg {
                            fill: $primary4;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                  }
            }
        }
    }

    .swiper-cards {
        .swiper-wrapper {
            padding-bottom: 10px;
        }
    }

    .profile-card {
        justify-content: flex-start;
        border: none;

        &__img {
            max-height: 207px;
            max-width: 207px;
        }

        &__title {
            font-size: 16px;
            margin-bottom: 8px;
            max-width: none;
        }

        &__text {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            text-align: center;

            color: #000000;
            font-size: 16px;
        }
    }

    i {
        display: block;
        font-weight: 500;
    }

    ul {
        list-style: disc;

        li {
            margin-left: 20px;
            list-style: disc;
        }
    }

    &__content {
        max-width: 740px;
    }

    @media screen and (max-width: $mobile) {
        padding-top: 14px;

        .container {
            padding: 0;
        }

        .mobile {
            display: block;
        }

        .desctop {
            display: none;
        }

        .swiper-cards {
            .swiper-slide {
                width: 235px;
            }
        }

        &__case {
            padding: 24px 20px;

            &-governance {
                padding: 36px 20px;
            }

            &-structure {
                padding: 36px 20px;
            }
            
            &-documents {
                padding: 36px 20px;
            }
        }

        &__governance {
            .associations__content {
                margin: 24px 0px;
            }
        }

        &__green {

            &-body {
                padding-left: 0px;
            }
        }

    }
}