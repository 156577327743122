.header {
  // position: relative;
  display: flex;
  // flex-direction: column;
  position: sticky;
  // position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-width: 260px;
  transform: translateX(0%);
  transition: all 0.5s ease-in-out;

  .arrow {
    position: absolute;
    z-index: 4;
    right: -18px;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
  }

  &--active {
    min-width: inherit;
    width: 100%;
    position: absolute;
    transform: translateX(-0px);
    transition: all 0.5s ease-in-out;

    .header {
      &__up {
        padding: 36px 8px 36px 8px;
        transition: all 0.5s ease-in-out;
      }

      &__logo {
        &--big {
          // display: none;
          opacity: 0;
          // height: 0;
          width: 0;
          transition: all 0.5s ease-in-out;
        }

        &--small {
          padding-top: 17px;
          width: 36px;
          height: 53px;
          opacity: 1;
          transition: all 0.5s ease-in-out;
        }
      }

      &__left {
        width: 64px;
        transition: all 0.5s ease-in-out;
      }

      &__profile {
        padding: 0px 8px 20px 8px;
        width: calc(100% + 16px);
        transform: translateX(-8px);

        &--auth {
          padding: 0px;
          width: auto;
          transform: none;
        }

        &-info {
          margin-left: 0;
          width: 0;
          flex: 0 0 auto;
          transition: all 0.5s ease-in-out;
        }

        &-img {
          // max-width: 72px;
          width: 48px !important;
          height: 48px !important;
          flex: 1 1 auto;
          transition: all 0.5s ease-in-out;

          &--verified {
            width: 22px;
            height: 22px;
            transition: all 0.5s ease-in-out;
          }
        }
      }

      &__list {
        // width: fit-content;
        // margin-right: 0;
        // margin: 0 auto;
        // align-items: center;
        margin: 0 13.5px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
      }

      &__item {
        // width: 20px;
        flex: 1 1 auto;
        transition: width 0.7s ease-in-out;
        // &-text {
        //   width: 0px;
        //   flex: 0 0 auto;
        //   transition: all 0.5s ease-in-out;
        // }

        // &-pro {
        //   width: 0;
        //   overflow: hidden;
        //   // display: none;
        //   // opacity: 0;
        // }
      }
    }

    .arrow {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &__left {
    flex: 0 0 auto;
    width: 260px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    height: 100%;
    transition: all 0.5s ease-in-out;

    &-info {
      overflow: auto;
    }
  }

  &__middle {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e8ecf5;
    padding: 4px 0px;
  }

  &__right {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 0 0 auto;
    background-color: #ffffff;
    border-left: 1px solid $primary3;
    // width: 0;
    width: 320px;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;

    &--active {
      // width: 320px;
      transform: translateX(0%);
      transition: all 0.5s ease-in-out;
    }

    &-link {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding-left: 26px;
      width: 100%;
      color: $primary1;
      transition: all 0.3s ease-in-out;

      svg {
        position: absolute;
        left: 0;
        fill: #404041;
        transition: all .3s ease-in-out;
        flex: 0 0 auto;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: $primary4;
          transition: all 0.3s ease-in-out;

          svg {
            fill: $primary4;
            transition: all 0.3s ease-in-out;
          }

          a {
            color: $primary4;
            transition: all 0.3s ease-in-out;
          }

          .header__right-count {
            color: $primary4;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    &-wrapper {
      padding: 23px 20px;
    }

    &-case {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &-title {
      margin-bottom: 20px;
    }

    &-info {
      cursor: pointer;
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      padding-left: 26px;
      position: relative;
      display: flex;
      justify-content: space-between;
      transition: all 0.3s ease-in-out;

      // &:not(.collapsed) {
      //   color: $primary4;
      //   transition: all 0.3s ease-in-out;
      //   svg {
      //     fill: $primary4;
      //     transition: all 0.3s ease-in-out;
      //   }
      // }

      svg {
        position: absolute;
        left: 0;
        fill: $primary1;
        transition: all 0.3s ease-in-out;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: $primary4;
          transition: all 0.3s ease-in-out;

          svg {
            fill: $primary4;
            transition: all 0.3s ease-in-out;
          }

          a {
            color: $primary4;
            transition: all 0.3s ease-in-out;
          }

          .header__right-count {
            color: $primary4;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    &-count {
      color: $primary2;
      margin-left: 4px;
      transition: all 0.3s ease-in-out;
    }

    &-container {

      .collapse,
      .collapsing {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 1px;
          height: 100%;
          left: 12px;
          top: 0;
          background-color: $primary1;
        }
      }

      >.collapse,
      .collapsing {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding-left: 15px;
      }

      &-collapse {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        margin-top: 20px;
      }
    }
  }

  &__logo {
    &-container {
      background-color: #ffffff;
      padding-bottom: 12px;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid $primary3;
    }

    &--mobile {
      display: none;
    }

    &-link {
      display: inline-block;
    }

    &--big {
      width: 135px;
      height: 66px;
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }

    &--small {
      opacity: 0;
      height: 0;
      width: 0;
      transition: all 0.5s ease-in-out;
    }
  }

  &__up {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding: 36px 12px 36px 20px;
    transition: all 0.5s ease-in-out;
  }

  &__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e8ecf5;
    width: calc(100% + 12px + 20px);
    transform: translateX(-20px);
    padding: 0px 12px 20px 20px;

    white-space: nowrap;
    flex: 1 1 auto;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &--auth {
      align-items: flex-start;
      flex-direction: row;
      padding: 0px;
      margin-bottom: 36px;
      border-bottom: none;
      width: auto;
      transform: none;

      .header__profile-img {
        width: 72px;
        height: 72px;
        margin-bottom: 0;
      }
    }

    &--mobile {
      display: none;
    }

    &-btns {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    &-title {
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    &-link {
      color: #778599;
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    &-info {
      margin-left: 20px;
      width: 120px;
      flex: 1 1 auto;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
    }

    &-img {
      flex: 0 0 auto;
      position: relative;
      width: 93px;
      height: 93px;
      margin-bottom: 20px;
      transition: all 0.5s ease-in-out;

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #778599;
        border-radius: 100%;
        overflow: hidden;
        height: 100%;
      }

      &--verified {
        position: absolute;
        left: -3px;
        bottom: -4px;
        width: 28px;
        height: 28px;
        transition: all 0.5s ease-in-out;
      }

      &--personal {
        max-width: 64px;
        max-height: 64px;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    // align-items: flex-start;
    padding-bottom: 20px;
    transition: all 0.5s ease-in-out;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    transition: all 0.7s ease-in-out;

    * {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: $primary1;
    }

    &--tg {
      * {
        color: $primary7;
      }
    }

    &--active {
      * {
        color: $primary4;
        fill: #17AA5A;
      }
    }

    &-icon {
      flex: 0 0 auto;
      margin-right: 4px;
      position: relative;
      width: 20px;
      height: 20px;

      svg {
        fill: #404041;
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      // width: 100%;
    }

    &-text {
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      width: 100px;
      white-space: nowrap;
      flex: 1 1 auto;
      // overflow: hidden;
      // transition: all 0.3s ease-in-out;
    }

    &-count {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      font-family: $FInter, $FDefault;
      font-size: 6px;
      font-weight: 600;
      line-height: normal;
      background-color: $primary10;
      border-radius: 100px;
      height: 11px;
      min-width: 11px;
    }

    &-pro {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 4px;
      // margin-left: auto;
      border-radius: 6px;
      background-color: $primary6;
      color: #fff;
      font-family: $FInter, $FDefault;
      font-size: 10px;
      font-weight: 600;
      line-height: normal;
      // opacity: 1;
      // transition: all 0.7s ease-in-out;
    }
  }

  &__exit {
    display: flex;
    align-items: center;
    margin-top: 56px;

    &-icon {
      margin-right: 4px;
      position: relative;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__active-point {
    color: $primary4;
    transition: all 0.3s ease-in-out;

    a {
      color: $primary4;
      transition: all 0.3s ease-in-out;
    }

    svg {
      fill: $primary4;
      transition: all 0.3s ease-in-out;
    }
  }

  @media screen and (max-width: $mobile) {
    display: inherit;
    position: relative;
    height: 100%;
    top: inherit;
    min-width: auto;
    transform: none;

    &--active {
      transform: none;

      .header {
        &__list {
          margin: 0;
        }

        &__up {
          left: 0;
          transition: all 0.3s ease-in-out;
        }

        &__left {
          display: inherit;
          width: auto;
        }

        &__profile {
          padding: 0px;
          width: auto;
          transform: none;

          &-img {
            width: 36px !important;
            height: 36px !important;
          }
        }

        &__logo {
          //&--big {
            //display: block;
            //opacity: 1;
            //width: 100px;
          //}

          &--small {
            display: none;
          }
        }
      }
    }

    &__left {
      display: inherit;
      width: auto;
    }

    &__right {
      display: none;
    }

    &__logo {
      &-container {
        justify-content: space-between;
        align-items: center;
        padding: 8px 20px;
      }

      &--small {
        display: none;
      }

      &--big {
        display: none;
      }

      &--mobile {
        display: block;
      }

      //&-link {
      //  img {
      //    width: 100px;
      //  }
      //}
    }

    .burger {
      display: flex;
    }

    &__profile {
      display: none;
      flex: 0 0 auto;
      padding: 0;

      &--mobile {
        display: block;
        margin-bottom: 0;
      }

      &--auth {
        .header__profile {
          &-img {
            margin-right: 0;
            width: 36px;
            height: 36px;

            svg {
              // left: ;
              // bottom: ;
              bottom: -2px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .arrow {
      display: none;
    }

    &__up {
      position: fixed;
      overflow: auto;
      z-index: 10;
      height: calc(100% - 57px);
      left: -100%;
      transition: all 0.3s ease-in-out;
    }

    &__item {
      &-text {
        font-size: 16px;
      }
    }
  }
}
