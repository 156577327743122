.footer {
  flex: 0 0 auto;
  background-color: #404041;
  margin-top: auto;

  .container {
    //height: 100%;
    margin: 0 auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__up {
    &-container {
      display: flex;
      justify-content: space-between;
      padding-top: 46px;
      padding-bottom: 35px;
    }
  }

  &__bottom {
    padding: 14px 0px;
    border-top: 1px solid #ffffff;
  }

  &__column {
    max-width: 288px;

    &-title {
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
      margin-bottom: 24px;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: $FInter, $FDefault;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #ffffff;
      font-family: $FInter, $FDefault;
      transition: all 0.3s ease-in-out;

      svg {
        fill: #AFB9C7;
        width: 17px;
        height: 17px;
        transition: all 0.3s ease-in-out;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #3C94D0;
          transition: all 0.3s ease-in-out;

          svg {
            fill: #3C94D0;
            transition: all 0.3s ease-in-out;
          }

        }
      }
    }

  }

  &__text {
    color: #ffffff;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
  }

  @media screen and (max-width: $mobile) {

    &__up {
      &-container {
        flex-direction: column;
        gap: 24px;
        padding-top: 36px;
        padding-bottom: 24px;
      }
    }

    &__text {
      font-size: 14px;
      text-align: center;
    }
  }
}
