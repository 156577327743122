html {
  min-height: 100 dvh;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100 dvh;
}


h2 {
  color: #4a4a4a;
  font-family: $FInter, $FDefault;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: $mobile) {
    font-size: 24px;
  }
}

.h2 {
  color: #4a4a4a;
  font-family: $FInter, $FDefault;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;

  @media screen and (max-width: $mobile) {
    font-size: 24px;
  }
}

h3 {
  color: #4a4a4a;
  font-family: $FInter, $FDefault;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.h3 {
  color: #4a4a4a;
  font-family: $FInter, $FDefault;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

h4 {
  color: #4a4a4a;
  font-family: $FInter, $FDefault;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.h4 {
  color: #4a4a4a;
  font-family: $FInter, $FDefault;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.mt-12 {
  margin-top: 12px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-36 {
  margin-bottom: 36px;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $primary9;
  font-family: $FInter, $FDefault;

  &:has(.database__header .dropdown-menu.show) {
    overflow: hidden;
  }

  &:has(.users__filters .dropdown-menu.show) {
    overflow: hidden;
  }
}

.container {
  width: 100%;
  max-width: 1126px;
  padding: 0 20px;
  //margin: 0 auto;

  @media screen and (max-width: $laptop) {
    // max-width: 745px;
  }
}

.flex {
  display: flex;

  &--transition {
    .flex {
      &__right {
        transition: none;
      }

      &__left {
        transition: none;

        .header {
          &__profile {
            transition: none;

            &-info {
              transition: none;
            }

            &-btns {
              .btn {
                transition: none;
              }
            }

            &-img {
              transition: none;
            }
          }

          &__list {
            transition: none;
          }

          &__up {
            transition: none;
          }
        }

        .header--active {
          .header {
            &__list {
              transition: none;
            }

            &__up {
              transition: none;
            }

            &__profile {
              transition: none;

              &-img {
                transition: none;
              }
            }

            &__logo--small {
              transition: none;
            }
          }

        }
      }


    }
  }

  &__left {
    position: sticky;
    z-index: 4;
    // width: 260px;
    // flex: 0 0 auto;
    // width: calc(260px + 320px);
    transition: all 0.5s ease-in-out;

    &--active {
      top: 0;
      height: 100vh;
      // width: 11px;
      width: 60px;
      // width: 329px;
      transition: all 0.5s ease-in-out;

      &-right {
        width: 329px;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  &__right {
    flex: 1 1 auto;
    width: 10px;
    //width: 100vw;
    // margin-left: auto;
    margin-bottom: 120px;
    transition: all 0.5s ease-in-out;
  }

  @media screen and (max-width: $mobile) {
    padding-top: 86px;
    flex-direction: column;

    &__right {
      margin-bottom: 80px;
      width: auto;
    }

    &__left {
      width: 100%;
      top: 0;
      //position: inherit;
      //position: relative;
      position: fixed;
      z-index: 1001;
    }
  }
}

.pages {
  padding: 20px;

  &__table {
    &-item {
      cursor: pointer;
      display: flex;

      &-box {
        position: fixed;
        top: 0;
        right: 0;
        width: 85%;
        flex: 1 1 auto;
        display: none;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;
        }
      }

      a {
        width: calc(15% - 40px);
        position: relative;
        padding: 10px 0px 10px 00px;
        color: $primary1;
        font-family: $FInter, $FDefault;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        border-bottom: 1px solid #000000;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .pages__table-item-box {
            display: block;
          }
        }
      }

      &--new {
        a {
          color: $primary4;
        }
      }

      // span:hover + .pages__table-item-box {
      //   display: block;
      // }

      // .pages__table-item-box:hover {
      //   display: block;
      // }
    }
  }
}

.custom-calendar {
  position: relative;
  // width: fit-content;
  width: 148px;
  font-size: 14px;
  height: fit-content;

  &--active {
    &::after {
      display: none;
    }
  }

  &::after {
    cursor: pointer;
    content: '';
    pointer-events: none;
    position: absolute;
    right: 1px;
    // width: 20px;
    height: 100%;
    // background-image: 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 4px;
    border-left: 2px solid #A5B2C3;
    background-image: url('/assets/images/calendar.svg');
    width: 38px;
  }

  input {
    cursor: pointer;
    width: 100%;
    position: relative;
    border: 1px solid #A5B2C3;
    padding: 10px 17px;
    border-radius: 12px;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    height: 36px;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 0;
      padding: 4px;
      border-left: 2px solid #A5B2C3;
      background-image: url('/assets/images/calendar.svg');
      width: 28px;
      height: 100%;
      background-image: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: $mediumMobile) {
    width: 100%;
  }
}

.slides-calendar {
  width: 100%;

  &__container {
    max-width: 856px;
    overflow: hidden;
  }

  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    // width: ;
    padding: 3px 10px;

    &--active {
      .slides-calendar {
        &__item {
          &-week {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 7px;
            text-decoration: underline;

            &::after {
              // position: absolute;
              content: '';
              display: inline-block;
              background-image: url('/assets/images/week-active.svg');
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }

    &-week {
      font-family: $FInter, $FDefault;
      font-size: 11px;
      text-transform: uppercase;
      color: $primary2;

      &--active {
        color: rgba($primary10, 0.6);
      }
    }
  }

}

.custom-tabs {
  &__items {
    display: flex;
    // column-gap: 24px;
    flex-wrap: wrap;
    row-gap: 8px;
    // border-bottom: 1px solid #778599;
    background-color: #ffffff;
    padding: 24px 36px 36px 36px;
    border-radius: 24px 24px 0px 0px;
  }

  &__item {
    display: flex;
    max-width: fit-content;
    flex: 1 1 auto;
    border-bottom: 1px solid red;
    padding-right: 20px;
    border-bottom: 1px solid #778599;
    padding-bottom: 12px;

    &:last-child {
      max-width: none;
    }
  }

  &__btn {
    position: relative;
    color: #404041;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    // transition: all 0.2s ease-in-out;

    span {
      color: #A5B2C3;
    }

    &::before {
      content: "";
      position: absolute;
      opacity: 0;
      bottom: -14px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #17aa5a;
      transition: all 0.2s ease-in-out;
    }

    &.active {
      font-weight: 600;
      // transition: all 0.2s ease-in-out;

      &::before {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__block {
    display: none;

    &.show {
      display: block;
    }
  }

  @media screen and (max-width: $table) {
    &__item {
      max-width: none;

      // &:last-child {
      //   max-width: none;
      // }
    }
  }
}

.swiper-cards {
  position: relative;

  .swiper-slide {
    // width: auto;
  }

  .swiper-cards__pagination {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    gap: 4px;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 1;
    margin: 0 !important;
    background-color: #E8ECF5;
  }

  .swiper-pagination-bullet-active {
    background-color: #A5B2C3;
    opacity: 1;
  }

  &__btn {
    cursor: pointer;
    position: absolute;
    z-index: 2;

    &.swiper-button-lock {
      display: none;
    }

    &--next {
      right: -55px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--prev {
      left: -55px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: $mobile) {

    width: calc(100% + 40px);
    transform: translateX(-20px);

    .swiper {
      padding: 0px 20px;
    }

    .swiper-cards__btn {
      display: none;
    }

    .swiper-slide {
      width: 182px;
    }

  }
}

.swiper-items-img {
  position: relative;

  .swiper-slide {
    // width: auto;
  }

  .swiper-items-img__pagination {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    gap: 4px;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 1;
    margin: 0 !important;
    background-color: #E8ECF5;
  }

  .swiper-pagination-bullet-active {
    background-color: #A5B2C3;
    opacity: 1;
  }

  &__btn {
    cursor: pointer;
    position: absolute;
    z-index: 2;

    &.swiper-button-lock {
      display: none;
    }

    &--next {
      right: -55px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--prev {
      left: -55px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: $mobile) {
    .swiper-items-img__btn {
      display: none;
    }
  }
}

.burger {
  display: none;
  cursor: pointer;

  flex-direction: column;
  // justify-content: space-between;

  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  &--active {
    .burger {
      &-click {
        position: relative;
        width: 18px;
        height: 18px;

        &::before {
          position: absolute;
          top: 8px;
          // bottom: 8px;
          left: -2px;
          transform: rotate(45deg);
          transition: all 0.3s ease-in-out;
        }

        span {
          position: absolute;
          opacity: 0;
          transition: all 0.1s ease-in-out;
        }

        &::after {
          position: absolute;
          bottom: 8px;
          left: -2px;
          transform: rotate(-45deg);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  &-click {
    position: relative;
    display: flex;
    width: 24px;
    height: 16px;

    &::before {
      position: absolute;
      top: 0;
      content: '';
      width: 24px;
      height: 2px;
      border-radius: 3px;
      background-color: $primary4;
      transition: all 0.3s ease-in-out;
    }

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 2px;
      border-radius: 3px;
      background-color: $primary4;
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }

    &::after {
      position: absolute;
      bottom: 0;
      content: '';
      width: 24px;
      height: 2px;
      border-radius: 3px;
      background-color: $primary4;
      transition: all 0.3s ease-in-out;
    }
  }
}

.btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 12px 28px;
  border-radius: 28px;
  color: #fff;
  font-family: $FInter, $FDefault;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  &--center {
    display: flex;
    margin: 0 auto;
  }

  &--small {
    padding: 4px 12px;
    border-radius: 16px;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 4px;
  }

  &--white {
    color: $primary4;
    background-color: #ffffff;

    svg {
      fill: $primary4;
      transition: all 0.3s ease-in-out;
    }
  }

  &--green {
    background-color: $primary4;
    transition: all 0.3s ease-in-out;

    svg {
      fill: #ffffff;
      transition: all 0.3s ease-in-out;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #06774e;
        transition: all 0.3s ease-in-out;

        svg {
          fill: #ffffff;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  &--solid {
    color: $primary4;
    border: 1px solid $primary4;
    transition: all 0.3s ease-in-out;

    svg {
      fill: $primary4;
      transition: all 0.3s ease-in-out;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #06774e;
        border: 1px solid #06774e;
        transition: all 0.3s ease-in-out;

        svg {
          fill: #06774e;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  &--solid-gray {
    color: #778599;
    border: 1px solid #778599;
    transition: all 0.3s ease-in-out;

    svg {
      fill: #778599;
      transition: all 0.3s ease-in-out;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #06774e;
        border: 1px solid #06774e;
        transition: all 0.3s ease-in-out;

        svg {
          fill: #06774e;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  &--blue {
    background-color: $primary7;
    transition: all 0.3s ease-in-out;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #0d6cad;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &--gray {
    border-radius: 12px;
    background: #a5b2c3;
    box-shadow: 10px 10px 10px 0px rgba(106, 110, 131, 0.03);
  }

  &--solid-gray-disabled {
    cursor: default;
    color: #778599;
    border: 1px solid #778599;
    transition: all 0.3s ease-in-out;

    svg {
      fill: #778599;
      transition: all 0.3s ease-in-out;
    }
  }

  &:disabled {
    cursor: default;
    background: #778599;
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  &--bg {
    background-color: $primary4;
    border-radius: 100%;
    padding: 4px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &__left {
    transform: rotate(0deg);
  }

  &__right {
    transform: rotate(180deg);
  }
}

.video {
  display: flex;
  flex-direction: column;
  max-width: 346px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 10px 10px 10px 0px rgba(106, 110, 131, 0.03);
  overflow: hidden;
  height: 100%;

  &__up {
    position: relative;
    width: 100%;
    // min-height: 195px;
    overflow: hidden;
  }

  &__down {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    padding: 12px 20px 20px 14px;
  }

  &__wrapper {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    background-color: #000000;
    transition: all 0.4s ease-in-out;

    iframe {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &--active {
      .video__wrapper {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  &__time {
    pointer-events: none;
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: inline-flex;
    align-items: center;
    font-family: $FInter, $FDefault;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    padding: 4px 8px;
    opacity: 1;

    // transition: all 0.4s ease-in-out;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__title {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    margin-right: auto;
  }

  &__watch {
    display: flex;
    //justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    // text-align: end;
    // margin-left: auto;

    a {
      color: $primary1;
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      text-decoration: underline;

      &.btn--green {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


  }

  @media screen and (max-width: $table) {
    &__watch {

      a {
        padding: 10px;
      }
    }
  }
}

.play {
  cursor: pointer;

  &--closed {
    pointer-events: none;
    cursor: default;
  }

  svg {
    pointer-events: none;
  }
}

.search {
  position: relative;
  color: #778599;
  font-family: $FInter, $FDefault;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  &__icon {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 14px;
      height: 14px;
    }
  }

  input {
    padding: 14px 24px 14px 46px;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #778599;

    &::placeholder {
      color: #778599;
      font-family: $FInter, $FDefault;
      font-size: 14px;
    }
  }

  @media screen and (max-width: $mobile) {
    input {
      padding: 11px 24px 12px 46px;
    }
  }
}

.search-list {
  position: relative;
  color: #778599;
  font-family: $FInter, $FDefault;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  &__container {
    position: relative;
  }

  &__icon {
    display: flex;
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 14px;
      height: 14px;
    }

    &--clear {
      right: 24px;
      left: auto;

      svg {
        width: 20px;
        height: 20px;
      }
    }

  }

  input {
    padding: 11px 48px 12px 46px;
    width: 100%;
    border-radius: 24px;
    border: 1px solid #778599;
    background-color: #ffffff;

    &::placeholder {
      color: #778599;
      font-family: $FInter, $FDefault;
      font-size: 14px;
    }
  }

  &__items {
    display: none;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #AFB9C7;
    border-top: none;
    border-radius: 0px 0px 24px 24px;
    padding-bottom: 10px;
    background-color: #ffffff;
  }

  &__item {
    cursor: pointer;
    color: #2C3050;
    padding: 4px 24px;
    transition: all 0.3s ease-in-out;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #E2E8F0;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &--active {
    .search-list {
      &__items {
        display: flex;
      }
    }

    input {
      border-radius: 24px 24px 0px 0px;
      border-bottom: none;
    }
  }


  //@media screen and (max-width: $mobile){
  //  input {
  //    padding: 11px 48px 12px 46px;
  //  }
  //}
}

.switch {
  display: inline-block;

  label {
    cursor: pointer;

    span {
      display: inline-block;
      position: relative;
      width: 72px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #778599;
      padding: 2px;
      background-color: #a5b2c3;
      transition: all 0.3s ease-in-out;

      &::after {
        position: absolute;
        content: '';
        background-color: #fff;
        left: 1px;
        top: 1px;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }

    input {
      display: none;
    }

    input:checked {
      ~ span {
        background-color: $primary4;
        transition: all 0.3s ease-in-out;

        &::after {
          left: calc(100% - 36px - 1px);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

.custom-checkbox {
  display: inline-block;

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  span {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    padding: 7px 5px;
    border: 2px solid #a5b2c3;
    flex: 0 0 auto;
    border-radius: 6px;

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  input {
    display: none;
  }

  input:checked {
    ~ span {
      border: 2px solid $primary4;

      &::after {
        background-image: url('/assets/images/checked.svg');
      }
    }
  }
}

.custom-radio {
  display: inline-flex;

  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    span {
      // position: relative;
      width: 15px;
      height: 15px;
      // flex: 0 0 auto;
      display: inline-block;
      // border-radius: 100%;
      // border: 1px solid #a5b2c3;
      background-image: url('/assets/images/radio-one.svg');
      background-repeat: no-repeat;
    }

    input:checked {
      ~ span {
        // border: 1px solid $primary4;

        background-image: url('/assets/images/radio-two.svg');
        background-repeat: no-repeat;

        // &::after {
        //   position: absolute;
        //   content: '';
        //   width: 9px;
        //   height: 10px;
        //   display: inline-block;
        //   // left: 50%;
        //   // top: 51%;
        //   // transform: translate(-50%, -50%);
        //   border-radius: 100%;
        //   background-color: $primary4;
        // }
      }
    }

    input {
      display: none;
    }
  }

  &__text {
    margin-left: 8px;
    color: #4a4a4a;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 600;
  }
}

.banner {
  overflow: hidden;
  border-radius: 24px;
  background-color: #cdf5e4;
  box-shadow: 10px 10px 10px 0px rgba(106, 110, 131, 0.03);
  // height: 254px;

  &--small {
    position: relative;
    max-width: 320px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 112%;
      max-width: none;
      height: 100%;
      filter: brightness(60%);
    }
  }

  &--video {
    &::after {
      display: none;
    }
  }

  &--custom-img {
    width: 100%;
  }

  &__wrapper {
    position: relative;
    box-shadow: 10px 10px 10px 0px rgba(106, 110, 131, 0.03);
    padding: 30px;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    .banner__text {
      color: #ffffff;
    }
  }

  &__container {
    display: flex;
    // height: 254px;
  }

  &__right {
    position: relative;
    display: flex;
    flex: 0 0 auto;

    img {
      margin-top: auto;
    }
  }

  &__left {
    padding: 36px 0px 36px 52px;
  }

  &__title {
    margin-bottom: 12px;
    color: #4a4a4a;
    font-family: $FInter, $FDefault;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;

    &.h3 {
      font-size: 24px;
    }
  }

  &__text {
    margin-bottom: 36px;
    color: #4a4a4a;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  &__video {
    position: relative;
    flex: 1 1 auto;

    &-content {
      img {
        width: 100%;
        filter: brightness(50%);
      }
    }

    &-play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__btn {
    width: fit-content;
  }

  @media screen and (max-width: $laptop) {
    width: 50%;
    max-width: none;
    overflow: hidden;
    position: relative;

    &--custom-img {
      width: 100%;

      &::after {
        display: none;
      }

      .banner {

        &__right {
          position: relative;
          width: 240px;
        }
      }
    }

    &__left {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 3;
      width: 100%;

      .btn {
        margin-top: auto;
        justify-content: center;
      }
    }

    &__title {
      font-size: 24px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #cdf5e4;
      opacity: 0.8;
    }

    &--small {
      &::after {
        display: none;
      }
    }

    &__right {
      position: absolute;
      z-index: 0;
      bottom: 0px;
      width: 100%;

      // height: 100%;
      img {
        // width: 100%;
        // height: 100%;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: $table) {

    &__left {
      padding: 24px;
    }

    &--custom-img {
      overflow: visible;
      padding-bottom: 166px;

      .banner {
        &__container {
          flex-direction: column;
        }

        &__right {
          position: absolute;
          right: 0;
          bottom: -65px;
          width: 250px;
        }
      }
    }
  }
}

.library-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 10px 10px 10px 0 rgba(106, 110, 131, 0.03);
  background-color: #17AA5A;
  border-radius: 24px;
  overflow: hidden;
  padding: 9px 57px 9px 37px;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 500px;
    height: 44px;
    background-image: url("/local/templates/main/source/app/assets/images/green-circle.svg");
    background-repeat: no-repeat;
    right: 32px;
    bottom: 0;
  }

  &__title {
    position: relative;
    z-index: 2;
    font-weight: 600;
    font-size: 32px;
    color: #fff;
  }

  &__img {
    position: relative;
    z-index: 2;
  }

  @media screen and (max-width: $mobile){
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    padding: 24px 20px 7px 20px;

    &__img {
      width: 100%;
      text-align: end;
    }
  }

  @media screen and (max-width: $mediumMobile) {
    &::after {
      right: -60px;
    }

    &__title {
      font-size: 24px;
    }
  }
}

.pay-banner {
  background-color: $primary5;
  border-radius: 20px;
  box-shadow: 2px 2px 14px 0px #e5edf4;

  &__container {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
    padding: 36px;
  }

  &__left {
    // max-width: 316px;
  }

  &__btn {
    color: $primary1;

    &--mobile {
      display: none;
    }
  }

  &__right {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 264px;
  }

  &__title {
    display: flex;
    align-items: center;
    column-gap: 24px;
    color: #fff;
    margin-bottom: 21px;

  }

  &__text {
    max-width: 587px;
    margin-bottom: 24px;
    color: #fff;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  &__card {
    min-width: 264px;
    border-radius: 20px;
    border: 2px solid #fff;
    padding: 36px 14px 14px 36px;
    box-shadow: 10px 10px 10px 0px rgba(9, 122, 60, 0.2);

    &-title {
      margin-bottom: 14px;
      color: #fff;
      font-family: $FInter, $FDefault;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
      max-width: 178px;
    }

    &-text {
      margin-bottom: 16px;
      color: #fff;
      font-family: $FInter, $FDefault;
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
    }

    &-btn {
      width: fit-content;
      margin-left: auto;
      text-align: end;
    }
  }

  @media screen and (max-width: $laptop) {
    &__card {
      width: 100%;
      min-width: auto;
    }

    &__left {
      max-width: none;
    }

    &__right {
      flex-direction: column;
    }

    &__container {
      flex-direction: column;
      padding: 36px 28px;
    }

    &__btn {
      display: none;

      &--mobile {
        margin-top: 24px;
        display: flex;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    &__title {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  column-gap: 8px;

  &--active {
    &.btn {
      background-color: #404041;
    }
  }

  &__name {
    &--back {
      padding-right: 12px;

    }

    &--next {
      padding-left: 12px;
    }
  }

  .btn {
    min-width: 36px;
    height: 36px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.swiper-wrapper {
  height: auto !important;
}

.swiper-slide {
  height: auto !important;
}

.slide {
  display: flex;

  &__container {
    padding: 56px 0px 32px 0px;
    width: 150px;
    flex: 1 1 auto;
  }

  &--green {
    background-color: #e2f4ed;
  }

  &__btns {
    margin-top: 36px;
    text-align: right;

    &-container {
      display: inline-flex;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        background-color: #778599;
        border-radius: 0.5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__btn {
    cursor: pointer;
    background-image: url('/assets/images/arrow-left-gray.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 47px;
    height: 47px;

    &--next {
      transform: rotate(180deg);
    }
  }

  &__pagination {
    display: none;
    column-gap: 4px;
  }

  .swiper {
    width: calc(100% + (20px * 2));
    transform: translateX(-20px);
    padding: 0 20px;

    //&-wrapper {
    //  gap: 24px;
    //}

    &-pagination-bullet {
      margin: 0 !important;
      background-color: #a5b2c3;
      opacity: 1;

      &-active {
        background-color: #17aa5a;
      }
    }

    &-slide {
      width: 346px;
    }
  }

  @media screen and (max-width: 1200px) {
    &__btns {
      display: none;
    }

    &__pagination {
      margin-top: 24px;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: $mobile) {
    .h2 {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: $mediumMobile) {
    .video {
      max-width: none;
    }

    &__container {
      padding: 24px 20px 46px 20px;
    }

    .swiper {
      width: calc(100% + 40px);
      transform: translate(-20px);
      padding: 0 7px;
    }
  }
}

.case {
  border-radius: 24px;
  background: #fff;
  box-shadow: 10px 10px 10px 0px rgba(106, 110, 131, 0.03);
}

.news {
  display: flex;
  flex-direction: column;
  max-width: 355px;
  padding: 14px;

  .badge-info {
    margin-bottom: 0;
  }

  &--big {
    padding: 20px;

    .news {
      &__up {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__img {
        width: auto;
        flex: 1 1 auto;
      }


    }
  }

  &__up {
    display: flex;
    justify-content: space-between;
    column-gap: 12px;
  }

  &__img {
    flex: 0 0 auto;
    width: 129px;
    height: 124px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #404041;
    font-family: $FInter, $FDefault;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    //max-width: 180px;
  }

  &__text {
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: $primary2;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__img {
    overflow: hidden;
    border-radius: 20px;
  }

  &__down {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  &__info {
    display: flex;
    column-gap: 8px;

    &-icon {
      margin-right: 4px;
    }

    &-item {
      display: flex;
      color: $primary2;
      font-family: $FInter, $FDefault;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__date {
    color: $primary2;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  @media screen and (max-width: $laptop) {
    max-width: none;
    width: 100%;
  }

  @media screen and (max-width: $mediumMobile) {
    &--big {
      .news {
        &__img {
          min-height: 173px;
        }
      }
    }

    &__title {
      font-size: 16px;
    }

    &__img {
      width: 106px;
      height: 101px;
    }
  }

  @media screen and (max-width: $smallMobile) {
    width: 100%;
    &__img {
      width: 77px;
      height: 73px;
    }

    &__title {
      font-size: 12px;
    }

    &__text {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.study {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  padding: 36px 26px 20px 24px;

  &--big {
    row-gap: 28px;
    background-color: $primary5;

    .study {
      &__title {
        color: #fff;
      }

      &__text {
        display: inherit;
        color: #fff;
      }

      &__img {
        display: flex;

        svg {
          margin-left: auto;
        }
      }
    }

    .btn {
      color: #4a4a4a;
    }
  }

  &__title {
    display: flex;
    column-gap: 8px;
    margin-bottom: 12px;
    color: #4a4a4a;
    font-family: $FInter, $FDefault;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 14px;
    color: #4a4a4a;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.dropdown {
  position: relative;

  &__btn {
    position: relative;
    width: fit-content;
  }

  &--selected {
    .dropdown__selected {
      display: flex;
    }

    .btn {
      color: #06774e;
      border: 1px solid #06774e;

      svg {
        fill: #06774e;
      }
    }
  }

  &__selected {
    display: none;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    position: absolute;
    right: 12px;
    top: -12px;
    border-radius: 100%;
    background-color: #17AA5A;
    width: 28px;
    height: 28px;
  }

  &--error {
    .custom-select__container-select {
      border: 1px solid #E63323;
    }
  }

  @media screen and (max-width: $mobile) {
    &__selected {
      font-size: 12px;
      right: 8px;
      top: -8px;
      width: 24px;
      height: 24px;
    }
  }
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  max-height: 80vh;

  z-index: 10;
  padding: 14px 0px;
  border-radius: 16px;
  background-color: #f8f9fd;
  box-shadow: 10px 10px 10px 0px rgba(106, 110, 131, 0.03);
  overflow: auto;
}

.dropdown-menu:not(.show) {
  display: none;
}

.dropdown-item {
  display: flex;
  transition: all 0.3s ease-in-out;

  a {
    cursor: pointer;
    width: 100%;
    color: $primary1;
    padding: 8px 8px 8px 14px;
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $primary3;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #e8ecf5;
      transition: all 0.3s ease-in-out;
    }
  }
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown {
  &--anim {

    .custom-select {
      &__container-select {
        transition: all 0.1s ease-in-out;
        transition-delay: 0.2s
      }

      &.show {
        .custom-select__container-select {
          border-bottom: none;
          border-radius: 12px 12px 0px 0px;
          transition: all 0.1s ease-in-out;
          //transition-delay: 1s
        }
      }
    }

    .custom-checkbox {
      width: 100%;
      label {
        font-size: 14px;
        line-height: normal;
      }

      span {
        width: 18px;
        height: 18px;

        &::after {
          background-size: 80%;
        }
      }
    }

    .dropdown-item {
      padding: 5px 0px;
      padding-left: 13px;
      border: none;
    }

    .dropdown-menu {
      padding: 0;
      background-color: #ffffff;
      overflow: hidden;
      border-radius: 0;
      transition: max-height 0.7s ease-in-out;

      &:not(.show) {
        display: block;
        max-height: 0px;
        transition: max-height 0.3s ease-in-out;
      }

      & > ul {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 14px 0px;
        overflow: auto;
        border: 1px solid #a5b2c3;
        border-top: none;
        border-radius: 0px 0px 12px 12px;
      }
    }
  }
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.collapse:not(.show) {
  display: none !important;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 24px;
    background-color: #778599;
    /* padding вокруг плашки */
    border: 2px solid white;
  }
}

.back-header {
  margin-bottom: 20px;

  &__arrow {
    display: inline-flex;
    align-items: center;
    color: #778599;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
}

.comment {
  &__wrapper {
    padding: 36px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__case {
    display: flex;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  &__profile {
    flex: 0 0 auto;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid #778599;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__field {
    padding: 14px 20px;
    width: 100%;
    height: 80px;
    border-radius: 16px;
    border: 1px solid #778599;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    &::placeholder {
      color: #778599;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }

    &.field-empty ~ .comment__placeholder {
      display: block;
    }


    //&:empty~.comment__placeholder {
    //  display: block;
    //}
  }

  &__placeholder {
    display: none;
    //display: block;
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 14px;
    color: #778599;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  &__case-field {
    position: relative;
    width: 100%;
  }

  &__btn {
    text-align: right;
  }

  @media screen and (max-width: $mobile) {
    &__profile {
      display: none;
    }

    &__wrapper {
      padding: 24px 14px;
    }

    &__btn {
      .btn {
        width: 100%;
      }
    }
  }
}

.badge-info {
  margin-bottom: 12px;

  &__list {
    display: flex;
    align-items: center;
    column-gap: 13px;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 2px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__count {
    color: #778599;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}

.card-document {
  &__container {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    gap: 13px;
    align-items: flex-end;
    padding: 36px;
  }

  &__right {
    display: flex;
    column-gap: 10px;

    .btn--solid-gray {
      border-radius: 14px;
      padding: 6px 8px;
    }
  }

  &__text {
    margin-bottom: 10px;
    color: #778599;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  &__title {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;

    svg {
      flex: 0 0 auto;
    }
  }

  @media screen and (max-width: $mobile) {
    &__container {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.user-card {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 8px;
    padding: 14px 10px 20px 14px;
  }

  &__up {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__profile {
    display: flex;
    column-gap: 12px;
  }

  &__img {
    position: relative;

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 86px;
      height: 86px;
      border-radius: 100%;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
      }
    }

    &-verified {
      position: absolute;
      width: 28px;
      height: 28px;
      bottom: -4px;
      left: -4px;
    }
  }

  &__badges {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__texts {
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  &__location {
    display: flex;
    align-items: center;
    color: $primary2;
    font-family: $FInter, $FDefault;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
}

.badge {
  width: fit-content;
  white-space: nowrap;
  padding: 8px 14px;
  border-radius: 16px;
  background-color: #e2f4ed;
  color: #000;
  font-family: $FInter, $FDefault;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.slides-big {
  position: relative;

  .btn {
    color: $primary1;
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    .arrow {
      width: 42px;
      height: 42px;

      &--bg {
        background-color: $primary1;
        border: 1px solid white;
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }

    &--prev {
      left: -18px;
    }

    &--next {
      right: -18px;
    }
  }

  .swiper-slide {
    display: flex;
    overflow: hidden;
    column-gap: 20px;
    border-radius: 24px;
    background: #355fc9;
    box-shadow: 10px 10px 10px 0px rgba(106, 110, 131, 0.03);
    padding: 36px 32px 32px 36px;
  }

  &__slide {
    &-left {
      position: relative;
      z-index: 1;

      .h2 {
        color: #fff;
        margin-bottom: 20px;
      }
    }

    &-right {
      position: relative;

      &::after {
        z-index: 0;
        position: absolute;
        content: '';
        background: linear-gradient(299deg, rgba(255, 255, 255, 0.44) 28.19%, rgba(255, 255, 255, 0) 91.03%);
        width: 508px;
        height: 508px;
        left: -100px;
        top: -100px;
        border-radius: 100%;
      }
    }

    &-img {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 290px;
      height: 290px;
      overflow: hidden;
      border-radius: 100%;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    &-badges {
      display: flex;
      column-gap: 12px;
      margin-bottom: 36px;

      .badge {
        color: $primary1;
        font-size: 18px;
        font-weight: 500;
        background-color: #ffffff;
      }
    }

    &-text {
      margin-bottom: 20px;
      color: #fff;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  @media screen and (max-width: $table) {
    position: relative;

    .swiper-slide {
      padding: 20px;
    }

    &__button {
      display: none;
    }

    &__slide {
      &-right {
        position: absolute;
        right: -100px;
        top: 50%;
        transform: translateY(-50%);

        &::after {
          // display: none;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
          background-color: #355fc9;
          opacity: 0.8;
        }
      }
    }
  }

  @media screen and (max-width: $mediumMobile) {
    .swiper-slide {
      .btn {
        width: 100%;
      }
    }
  }
}

.comment-item {
  &__wrapper {
    padding: 24px;
    display: grid;
    grid-template-columns: 82px 1fr;
    column-gap: 11px;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 82px;
    overflow: hidden;
    grid-area: 1/1/4/1;
    width: 82px;
    height: 82px;

    &:has(.header__profile-img--personal) {
      border: 1px solid #b0bbcb;
    }

  }

  &__title {
    margin-bottom: 8px;
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }

  &__text {
    margin-bottom: 36px;
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    &-text {
      display: flex;
      align-items: center;
      column-gap: 5px;
      color: $primary2;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &-btn {
      color: $primary6;
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media screen and (max-width: $mobile) {
    &__info {
      grid-area: 6 / 1 / 7 / 3;
    }

    &__text {
      margin-top: 14px;
      margin-bottom: 14px;
      grid-area: 4 / 1 / 4 / 3;
    }

    &__title {
      grid-area: 2 / 2 / 2 / 2;
    }
  }
}

.custom-field {


  &--radio {
    .custom-field__container-field {
      display: flex;
      gap: 36px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    &-field {
      position: relative;
    }
  }

  &__title {
    margin-bottom: 8px;
    color: #4a4a4a;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  input {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #a5b2c3;
    background-color: #fff;
    padding: 10px 35px 10px 13px;
    font-family: $FInter, $FDefault;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    &::placeholder {
      color: #a5b2c3;
      opacity: 1;
    }
  }

  &__btn {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__error {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 8px;
    color: #EC4139;
    font-size: 14px;
  }

  &--error {
    input {
      border: 1px solid #E63323;
    }
  }
}

.text-error {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  color: #EC4139;
  font-size: 14px;
}

.custom-file-upload {
  display: block;

  input {
    display: none;
  }

  span {
    cursor: pointer;
    padding: 26px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $primary2;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    border-radius: 24px;
    border: 1px dashed $primary2;
    background-color: $primary9;
  }

  &__text {
    &--mobile {
      display: none;
    }
  }

  &--active {
    span {
      border: 1px dashed $primary4;
      color: $primary4;
    }
  }

  @media screen and (max-width: $mobile) {
    span {
      padding: 24px 28px;
      text-align: center;
      flex-direction: column;
      row-gap: 14px;
      border: 1px dashed $primary4;
      color: $primary4;
    }

    &__text {
      display: none;

      &--mobile {
        display: block;
      }
    }

    .btn {
      font-size: 14px;
    }
  }
}

.popup-window-close-icon {
  top: 24px !important;
  right: 24px !important;

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'%3E%3Cpath d='M0.313608 10.9341C-0.0555325 11.3032 -0.0731106 11.9624 0.322397 12.3403C0.700327 12.7358 1.3683 12.7183 1.73744 12.3491L6.00013 8.08643L10.2628 12.3491C10.6408 12.7271 11.2911 12.7358 11.6691 12.3403C12.0646 11.9624 12.0558 11.3032 11.6779 10.9253L7.41517 6.6626L11.6779 2.40869C12.0558 2.02197 12.0646 1.37158 11.6691 0.993652C11.2911 0.598145 10.6408 0.606934 10.2628 0.984863L6.00013 5.24756L1.73744 0.984863C1.3683 0.615723 0.700327 0.598145 0.322397 0.993652C-0.0731106 1.37158 -0.0555325 2.03076 0.313608 2.3999L4.5763 6.6626L0.313608 10.9341Z' fill='%23404041'/%3E%3C/svg%3E") !important;
  }
}

.popup-form {
  max-width: 762px;
  padding: 56px !important;
  position: relative;
  border-radius: 20px !important;

  &__fields {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &:has(.login) {
    .popup-form {
      &__btns {
        button {
          width: 50%;
        }
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: center;
    column-gap: 8px;


  }

  &__text {
    margin-top: 24px;
    display: block;
    text-align: center;
    color: $primary2;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

.tooltip {
  max-width: 188px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: #fff9f4;
  box-shadow: 1px 1px 4px 0px rgba(86, 41, 0, 0.1);
  z-index: 100;
  padding: 12px;
  color: #9d9d9d;
  font-family: $FInter, $FDefault;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.custom-select {

  &--multiselect {
    .custom-select {
      &__container-select {
        height: auto;
        min-height: 42px;
        padding: 10px 13px;
      }
    }
  }

  &__title {
    margin-bottom: 8px;
    color: #4a4a4a;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  &__container {
    &-select {
      cursor: pointer;
      position: relative;
      border-radius: 12px;
      border: 1px solid #a5b2c3;
      background-color: #fff;
      height: 42px;
    }

    &-input {
      position: relative;
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
    color: #a5b2c3;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  &__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  &.show {
    .custom-select__arrow {
      svg {
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;

    svg {
      transition: all 0.3s ease-in-out;
    }
  }
}

.database-card {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px 20px 20px 36px;

  .badge-info {
    &__list {
      margin-bottom: 0;
    }
  }

  &__container {
    display: flex;
    column-gap: 13px;
    //padding-right: 200px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    color: $primary1;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }

  &__date {
    margin-bottom: 12px;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    color: $primary2;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: $FInter, $FDefault;
    font-size: 18px;
    font-weight: 600;
    color: $primary1;

    svg {
      flex: 0 0 auto;
      margin-right: 4px;
    }
  }

  &__name {
    margin-bottom: 8px;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    color: $primary2;
  }

  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: $FInter, $FDefault;
    font-size: 18px;
    //color: $primary1;
    color: $primary2;
    line-height: 28px;
  }

  &__right {
    flex: 0 0 auto;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 16px;
  }

  //@media screen and (max-width: $mobile) {
  //  .database-card__container {
  //    padding-right: 0;
  //  }
  //}

  @media screen and (max-width: $mediumMobile) {
    padding: 20px;

    &__container {
      row-gap: 12px;
      flex-direction: column;
    }

    &__description {
      -webkit-line-clamp: 7;
    }

    &__title {
      font-size: 16px;
    }

    &__right {
      height: auto;
      width: 100%;
    }

    &__img {
      width: 100%;
      height: 100%;
    }

    &__footer {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 12px;
    }
  }
}

.badge-status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  &__item {
    display: flex;
    align-items: center;
    font-family: $FInter, $FDefault;
    border-radius: 16px;
    padding: 8px 12px 8px 12px;
    border: 1px solid $primary2;

    svg {
      margin-right: 4px;
    }
  }
}

.custom-radio-switch {
  display: flex;
  position: relative;
  width: fit-content;
  border-radius: 28px;
  border: 1px solid $primary2;

  &:has(label:nth-child(2)input:checked) {
    &::after {
      left: 50%;
      transition: all 0.3s ease-in-out;
    }
  }

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    height: 100%;
    width: 50%;
    border-radius: 28px;
    background-color: $primary4;
    transition: all 0.3s ease-in-out;
  }

  label {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 12px 22px;
    z-index: 2;

    span {
      color: $primary2;
      transition: all 0.3s ease-in-out;
    }

    input {
      display: none;


      &:checked + span {
        color: #ffffff;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.custom-radio-status {
  display: flex;

  .badge-status__item {
    cursor: pointer;
  }

  input {
    display: none;
  }

  input:checked + .badge-status__item {
    border: 2px solid $primary4;
  }
}

.product-card {
  display: flex;
  width: 100%;
  padding: 8px;
  gap: 8px;

  &__title {
    margin-bottom: 8px;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }

  &__text {
    margin-bottom: 14px;
    font-family: $FInter, $FDefault;
    font-size: 14px;
    line-height: 17px;
  }
}

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $FInter, $FDefault;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  min-width: 100px;
  width: fit-content;
  border: 1px solid #A5B2C3;
  border-radius: 12px;
  //padding: 6px 12px;
  height: 36px;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $FInter, $FDefault;
    font-size: 18px;
    font-weight: 400;
    height: 100%;

    &--minus {
      padding-left: 12px;
    }

    &--plus {
      padding-right: 12px;
    }
  }
}

.order {
  .custom-field__title {
    font-weight: 600;
  }

  .researchbase-create {
    &__btns {
      max-width: 100%;
    }
  }

  &__result {
    margin-bottom: 14px;
    font-family: $FInter, $FDefault;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
}

.info-limited {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 20px;
  background-color: $primary5;
  border-radius: 16px;

  &--big {
    padding: 28px 36px;

    .info-limited {
      &__text {
        font-size: 24px;

        span {
          font-size: 32px;
        }
      }
    }
  }

  &__content {
    display: flex;
    //align-items: start;
    align-items: center;
    column-gap: 12px;
  }

  &__icon {
    padding: 4px;
    border-radius: 12px;
    background-color: #ffffff;
  }

  &__text {
    column-gap: 8px;
    font-family: $FInter, $FDefault;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;

    span {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 2px;
      vertical-align: middle;
    }

    a {
      font-family: $FInter, $FDefault;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  &--error {
    background-color: $primary10;
    border-radius: 0;

    .info-limited {
      padding: 8px 24px;

      &__content {
        align-items: center;
      }
    }
  }

  @media screen and (max-width: $laptop) {
    &--big {
      padding: 14px;

      .info-limited {
        &__text {
          font-size: 16px;

          span {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $table) {
    padding: 14px;

    &__text {
      font-size: 16px;

      span {
        font-size: 20px;
      }
    }

    &--big {
      flex-direction: column;
      row-gap: 24px;

      .info-limited {
        &__content {
          width: 100%;
        }

        &__btn {
          width: 100%;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}


.step {
  margin-top: 36px;
  padding: 36px 36px 64px 36px;

  //&__title {
  //  margin-top: 64px;
  //
  //}

  &__text {
    margin-top: 20px;
  }

  &__list {
    display: flex;
    gap: 19px;
    margin-top: 64px;
  }

  &__item {
    position: relative;
    border-radius: 24px;
    padding: 42px 16px 36px 24px;
    border: 1px solid $primary4;

    &-number {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      left: 24px;
      top: -24px;
      font-weight: 800;
      font-size: 24px;
      line-height: 22px;
      color: #ffffff;
      background-color: $primary4;
      width: 46px;
      height: 46px;
    }

    &-title {
      margin-bottom: 14px;
    }

    &-text {
      margin-bottom: 24px;
    }

    &-price {
      display: flex;
      gap: 8px;
      font-weight: 700;
      font-size: 32px;
      color: $primary4;
    }
  }

  @media screen and (max-width: $laptop) {
    &__list {
      flex-direction: column;
      gap: 40px;
    }

    &__item {
      padding: 42px 20px 36px 20px;
    }
  }

  @media screen and (max-width: $mobile) {
    width: calc(100% + 40px);
    transform: translateX(-20px);
    padding: 24px 20px 36px 20px;

    &__title {
      margin-bottom: 52px;
    }

    &__item {
      &-btn {
        width: 100%;

        .btn {
          width: 100%;
        }
      }
    }
  }
}

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary3;
  padding: 14px 14px 36px 14px;
  border-radius: 24px;
  box-shadow: 4px 4px 8px 0px rgba(106, 110, 131, 0.08);

  &__img {
    margin-bottom: 12px;
    max-width: 132px;
    width: 100%;
    max-height: 132px;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: 26px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    max-width: 154px;
  }

  &__text {
    font-size: 14px;
    color: $primary2;
  }

}

.basket-card {
  display: flex;
  gap: 12px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 5px 5px 8px 0px rgba(106, 110, 131, 0.08);

  &__left {
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
    width: 117px;
    height: 155px;
    border-radius: 20px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(#000000, 0.3);
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    flex: 1 1 auto;
  }

  &__icon {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
    word-break: break-word;
  }

  &__text {
    margin-bottom: 20px;
    font-size: 14px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__price {
    font-weight: 600;
    font-size: 14px;
  }

  &__delete {
    margin-left: auto;
  }

  &__content {
    max-width: 445px;
  }

  &__caption {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .counter {
    margin-bottom: 20px;
  }
}


.basket-empty {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  box-shadow: 5px 5px 8px 0 rgba(106, 110, 131, 0.08);
  background: #fff;

  &__left {
    max-width: 421px;
    padding: 36px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 19px 65px 19px 0px;

    img {
      min-width: 120px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    color: #404041;
    margin-bottom: 12px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: $laptop) {
    .basket-empty {
      &__left {
        padding: 19px;
      }

      &__right {
        padding: 19px 19px 19px 0px;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    &__left {
      padding: 0px 36px 36px 36px;
      max-width: none;
    }

    &__right {
      padding: 24px 0px 15px 0px;
      order: -1;
    }

    &__btn {
      width: 100%;
    }
  }
}

.questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
  padding: 36px;

  &-title {
    margin-bottom: 14px;

    span {
      color: $primary4;
    }
  }

  &-text {
    margin-bottom: 36px;

    a {
      color: $primary7;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: $mobile) {
    padding: 24px 20px;

    &-btn {
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
}

.selected-text {
  color: #17AA5A;
  background: #e855a5;
}

.popup-window--black {
  background: rgba(0, 0, 0, 0.8) !important;
  padding: 65px !important;
  max-width: 447px;

  .popup-window-close-icon {
    border-radius: 100%;
    background: #ffffff;
    opacity: 1;
  }

  .h3 {
    color: #ffffff;
    text-align: center;
    margin-bottom: 14px;
  }

  .text {
    color: #ffffff;
    text-align: center;
    margin-bottom: 24px;
  }
}

.popup-window {
  &:has(.login) {
    max-width: 474px;
    width: 100%;

    .popup-window-close-icon {
      opacity: 1;
    }
  }

  &:has(.registration) {
    max-width: 474px;
    width: 100%;

    .popup-window-close-icon {
      opacity: 1;
    }
  }

  &:has(.forgot-password) {
    max-width: 474px;
    width: 100%;

    .popup-window-close-icon {
      opacity: 1;
    }

    .h3 {
      margin-bottom: 8px;
    }
  }
}

.scroll-lock {
  overflow: hidden;
}

.not-found {
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 46px;

  &__text {
    color: #17AA5A;
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
    max-width: 335px;
  }

  @media screen and (max-width: $table) {
    &__text {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.popup-send-form {
  max-width: 468px;
  padding: 52px !important;

  &__text {
    font-family: $FInter, $FDefault;
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #404041;
  }
}



.notification {
  box-shadow: 5px 5px 8px 0 rgba(106, 110, 131, 0.08);
  background: #fff;
  border-radius: 16px;
  //max-height: 134px;

  &--disabled {
    background: #E8ECF5;
    opacity: 0.5;
    .notification__title {
      svg {
        fill: #A5B2C3;
      }
    }
  }

  &--btn {

    .notification__info {
      padding-bottom: 12px;
    }

    .notification__more {
      display: flex;
    }
  }

  &:has(.notification__more input:checked) {
    .notification__text {
      max-height: none;
      display: initial;
    }
  }

  //&:has(.notification__more) {
  //  .notification__info {
  //    padding-bottom: 12px;
  //  }
  //}


  &__info {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding: 24px;
    padding-bottom: 36px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 7px;
    font-weight: 600;
    font-size: 18px;
    color: #404041;
    margin-bottom: 8px;

    svg {
      flex: 0 0 auto;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    color: #404041;
    max-height: 40px;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      color: #3C94D0;
    }
  }

  &__right {
    flex: 0 0 auto;
  }

  &__date {
    font-weight: 600;
    font-size: 14px;
    line-height: 143%;
    color: #404041;
  }

  &__more {
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(232, 236, 245, 0.5);
    border-radius: 0 0 16px 16px;
    width: 100%;

    input {
      display: none;

      &:checked {
        & ~ svg{
          transform: rotate(180deg);
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    border-radius: 0px;

    &__more {
      border-radius: 0px;
    }

    &__info {
      flex-direction: column;
      gap: 8px;
      padding: 14px 20px;
    }

    &__right {
      order: -1;
    }

    &__date {
      text-align: end;
      font-weight: 600;
      font-size: 12px;
      line-height: 167%;
    }

    &__title {
      font-size: 16px;
    }
  }
}